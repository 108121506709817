import { PROD } from '@config/env'
import client from '@lib/http/client'
import useSWRImmutable from 'swr/immutable'

export const saveRestoreId = (restoreId: string) =>
  client.post('freshdesk/restoreid/save', { restoreid: restoreId })

interface UseGetHotjarInfo {
  account: {
    country: string
    identifier: string
    is_trial: boolean
    record_key: string
  }
}

export const useGetHotjarInfo = () =>
  useSWRImmutable<UseGetHotjarInfo>(
    PROD ? 'web-analytic-tools/v1/hotjar' : null
  )
