import { useWizardContext } from '@pages/Landings/routes/Landings/CreateLandingWizard/contexts'
import { pallets } from '@pages/Landings/routes/Landings/CreateLandingWizard/helpers/constants'
import { useGetFontsList } from '@services/fonts/useGetFontsList'
import { Suspense } from 'react'

import { getPreview } from './previews'
import styles from './SectionPreview.module.scss'

export const SectionPreview = ({ id }: { id: string }) => {
  const {
    formik: {
      values: {
        topic,
        palette,
        style,
        customPalette: { colors, font }
      }
    },
    selectedTopicImageNumbers
  } = useWizardContext()

  const { data: fontListData } = useGetFontsList()

  const getData = () => {
    if (palette === 'custom') {
      return {
        colors,
        font
      }
    }

    if (!style) {
      return {
        colors: [],
        font: ''
      }
    }

    return pallets[style][palette]
  }

  const data = getData()

  const fontUrl = fontListData?.collection[data.font.toLowerCase()].css_url

  return (
    <div className={styles.preview} data-testid='wizard-section-preview'>
      <link href={fontUrl} rel='stylesheet' />

      <Suspense>
        {getPreview({
          id,
          data,
          selectedTopicImageNumbers,
          topic
        })}
      </Suspense>
    </div>
  )
}
