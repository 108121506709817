import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import sidebarPromoBanner from '@images/sidebar/promoBanners/gift_banner.png'
import { Image, Paragraph, Spreader } from '@ui-kit'
import { motion } from 'framer-motion'
import { Trans } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './SidebarPromoBanner.module.scss'

export const SidebarPromoBanner = ({
  isSidebarMini,
  isLaptop,
  isSidebarMobileOpen
}: {
  isSidebarMini: boolean
  isLaptop: boolean
  isSidebarMobileOpen: boolean
}) => {
  const { user } = useUserContext()

  const hasAccessToSidebarBanner = useFeatureFlags('SIDEBAR_BANNER')

  const hasAccessToLinkToG2 = useFeatureFlags('SIDEBAR_LINK_TO_G2')

  const isInSQLStage = user?.platform_stage === 'SQL'

  const isInPaidStage = user?.platform_stage === 'PAID'

  const shouldBannerBeVisible = isInSQLStage || isInPaidStage

  const shouldBannerBeVisibleOnMobileOrLaptop = isSidebarMobileOpen && isLaptop

  const isVisible =
    !isSidebarMini &&
    shouldBannerBeVisible &&
    hasAccessToSidebarBanner &&
    !isLaptop

  const isVisibleOnMobileOrLaptop =
    !isSidebarMini &&
    shouldBannerBeVisible &&
    shouldBannerBeVisibleOnMobileOrLaptop &&
    hasAccessToSidebarBanner

  const linkToOpen = hasAccessToLinkToG2
    ? 'https://www.g2.com/contributor/landingi-vs-20?secure%5Bpage_id%5D=landingi-vs-20&secure%5Brewards%5D=true&secure%5Btoken%5D=2d32133ffccbec5394bd7f88a5a7f9fc8e66a7666d5ac41c4e3bce18b2fdd9e2'
    : 'https://reviews.capterra.com/products/new/5fbf7d27-7f2a-423b-b13a-a6d200b4d9ef/c10871fd-6282-4ba8-99bf-6333994ae5d2/?lang=en'

  const handleRedirectToCapterraReviews = () => {
    window.open(linkToOpen, '_blank')
  }

  if (isVisible || isVisibleOnMobileOrLaptop) {
    return (
      <motion.div
        key='content'
        initial='collapsed'
        animate='open'
        exit='collapsed'
        variants={{
          open: { opacity: 1 },
          collapsed: { opacity: 0 }
        }}
        transition={{ duration: 1.5, ease: [1, 0, 0.4, 1] }}
        className={styles.sidebarPromoBanner}
      >
        <Row onClick={handleRedirectToCapterraReviews}>
          <Image src={sidebarPromoBanner} width='42px' height='auto' />

          <Spreader spread={5} />

          <Row alignItems='center'>
            <Paragraph color='white' size={12}>
              <Trans i18nKey='sidebar.promo.banner.description' />
            </Paragraph>
          </Row>
        </Row>
      </motion.div>
    )
  }

  return null
}
