import { useStyles } from '@hooks/useStyles'
import { Paragraph } from '@ui-kit/Paragraph'
import { Tooltip } from '@ui-kit/Tooltip'

export interface TrimmedLabelProps {
  label: string
  maxNumberOfCharacters: number
  shouldDisplayTooltip?: boolean
  tooltipPlacement?: 'top' | 'left' | 'right' | 'bottom'
  className?: string | string[]
  'data-testid'?: string
}

export const TrimmedLabel = ({
  label,
  maxNumberOfCharacters,
  shouldDisplayTooltip = true,
  tooltipPlacement = 'top',
  className,
  'data-testid': dataTestId = 'trimmedLabel'
}: TrimmedLabelProps) => {
  const elementStyles: string = useStyles({}, className)

  const getTrimmedLabel = () => {
    return label.length > maxNumberOfCharacters
      ? `${label.slice(0, maxNumberOfCharacters).trim()}...`
      : label
  }

  return (
    <div className={elementStyles} data-testid={dataTestId}>
      {shouldDisplayTooltip ? (
        <Tooltip
          content={label}
          disabled={label.length <= maxNumberOfCharacters}
          placement={tooltipPlacement}
        >
          <Paragraph>{getTrimmedLabel()}</Paragraph>
        </Tooltip>
      ) : (
        <Paragraph>{getTrimmedLabel()}</Paragraph>
      )}
    </div>
  )
}
TrimmedLabel.displayName = 'TrimmedLabel'
