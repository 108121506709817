import { Acl } from '@components/Acl'
import { Redirect } from '@components/Redirect'
import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { lazy } from '@helpers/lazy'
import { FullLayout } from '@layouts/FullLayout'
import { PRODUCTS } from '@routes/path'

import { ProductsProvider } from './Products/contexts'

const Products = lazy(() =>
  import('@pages/Products/routes/Products').then(({ Products }) => ({
    default: Products
  }))
)

export const routes = [
  {
    path: PRODUCTS.PRODUCTS,
    element: (
      <Acl acl='ecommerce.view_products'>
        <Redirect
          ff='SPA_PRODUCTS'
          url={`${NEW_LANDINGI_APP_URL}/ecommerce${PRODUCTS.PRODUCTS}`}
        >
          <FullLayout />
        </Redirect>
      </Acl>
    ),
    children: [
      {
        path: PRODUCTS.PRODUCTS,
        element: (
          <ProductsProvider>
            <Products />
          </ProductsProvider>
        )
      }
    ]
  }
]
