import { Acl } from '@components/Acl'
import { Redirect } from '@components/Redirect'
import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { lazy } from '@helpers/lazy'
import { FullLayout } from '@layouts/FullLayout'
import { LIGHTBOXES } from '@routes/path'

const LigthboxesPage = lazy(() =>
  import('@pages/Lightboxes/routes/Lightboxes').then(({ LightboxesPage }) => ({
    default: LightboxesPage
  }))
)

export const routes = [
  {
    path: LIGHTBOXES.LIGHTBOXES,
    element: (
      <Acl acl='lightboxes.view_list'>
        <Redirect
          ff='SPA_LIGHTBOXES'
          url={`${NEW_LANDINGI_APP_URL}/${LIGHTBOXES.LIGHTBOXES}`}
        >
          <FullLayout />
        </Redirect>
      </Acl>
    ),
    children: [
      {
        path: LIGHTBOXES.LIGHTBOXES,
        element: <LigthboxesPage />
      }
    ]
  }
]
