import { AxiosError } from 'axios'
import useSWRImmutable from 'swr/immutable'

export type GetAllFontsResponse = {
  collection: {
    [key: string]: {
      css_url: string
      name: string
      type: 'generic' | 'google' | 'own'
    }
  }
}

export const useGetFontsList = () => {
  const { data, mutate } = useSWRImmutable<GetAllFontsResponse, AxiosError>(
    `fonts-all`
  )

  const dataAsArray = data ? Object.values(data.collection) : []
  return { data, dataAsArray, mutate }
}
