import { Fragment } from 'react'

import { Pagination } from './Pagination'
import { Table } from './Table'
import { ItemBase, useTableProps } from './types'

export const useTable = <Item extends ItemBase>({
  data,
  columns,
  rowActions,
  emptyMessage,
  pagination,
  isLoading,
  hasFullBorder
}: useTableProps<Item>) => {
  return {
    Table: () => (
      <Fragment>
        <Table
          data={data}
          columns={columns}
          rowActions={rowActions}
          emptyMessage={emptyMessage}
          isLoading={isLoading}
          hasFullBorder={hasFullBorder}
        />
        {isLoading || !pagination ? null : <Pagination {...pagination} />}
      </Fragment>
    )
  }
}
