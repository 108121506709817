export const getColumnWidth = <Item>(
  width: string | ((item?: Item) => string) | undefined,
  data?: Item
) => {
  if (!width) {
    return '1fr'
  }

  return typeof width === 'function' ? width(data) : width
}
