import NoDataImage from '@images/chart_no_data.svg'
import NotFoundImage from '@images/search_empty.svg'

export const elementSizes = {
  small: {
    imageHeight: 82,
    titleLevel: 5,
    messageSize: 10,
    spaceSize: 10
  },
  medium: {
    imageHeight: 150,
    titleLevel: 3,
    messageSize: 14,
    spaceSize: 20
  },
  large: {
    imageHeight: 420,
    titleLevel: 1,
    messageSize: 18,
    spaceSize: 20
  }
} as const

export const messageTypes = {
  'no-data': {
    title: 'message.no.data.results',
    message: 'message.no.data.results.small',
    image: NoDataImage,
    size: 'medium'
  },
  'not-found': {
    title: 'message.empty.search.results',
    message: 'message.empty.search.results.small',
    image: NotFoundImage,
    size: 'medium'
  }
} as const
