import { Button } from '@ui-kit/Button'
import { Icon } from '@ui-kit/Icon'
import { Paragraph } from '@ui-kit/Paragraph'
import { Spacer } from '@ui-kit/Spacer'
import { Spreader } from '@ui-kit/Spreader'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import { PageSize } from './PageSize'
import styles from './Pagination.module.scss'
import type { Pagination as PaginationType } from './types'

export const Pagination = ({
  handlePageChange,
  current,
  pageCount,
  pageSize,
  handlePageSizeChange
}: PaginationType) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <Spacer space={10} />

      <Row className={styles.pagination} vertical='center' horizontal='end'>
        {pageCount > 1 ? (
          <Row
            className={styles.pagination__pages}
            data-testid='pagination'
            alignItems='center'
            justifyContent='center'
          >
            <Button
              variant='text-neutral'
              disabled={current === 1}
              onClick={() => handlePageChange(1)}
            >
              {t('pagination.word.first')}
            </Button>

            <Button
              variant='text-neutral'
              disabled={current === 1}
              onClick={() => handlePageChange(current - 1)}
              isSquare
              data-testid='prev-button'
            >
              <Icon icon='icon-caret-left' />
            </Button>

            <Button
              variant='text-neutral'
              onClick={() => handlePageChange(current - 2)}
              style={{ display: current - 2 <= 0 ? 'none' : 'flex' }}
              isSquare
            >
              {current - 2}
            </Button>

            <Button
              variant='text-neutral'
              onClick={() => handlePageChange(current - 1)}
              style={{ display: current - 1 <= 0 ? 'none' : 'flex' }}
              isSquare
            >
              {current - 1}
            </Button>

            <Button variant='text-neutral' active isSquare>
              {current}
            </Button>

            <Button
              variant='text-neutral'
              onClick={() => handlePageChange(current + 1)}
              style={{ display: current + 1 > pageCount ? 'none' : 'flex' }}
              isSquare
            >
              {current + 1}
            </Button>

            <Button
              variant='text-neutral'
              onClick={() => handlePageChange(current + 2)}
              style={{ display: current + 2 >= pageCount ? 'none' : 'flex' }}
              isSquare
            >
              {current + 2}
            </Button>

            <Button
              variant='text-neutral'
              onClick={() => handlePageChange(current + 3)}
              style={{ display: current + 4 !== pageCount ? 'none' : 'flex' }}
            >
              {current + 3}
            </Button>

            {current + 4 < pageCount && (
              <Paragraph color='neutral-6'>...</Paragraph>
            )}

            <Button
              variant='text-neutral'
              onClick={() => handlePageChange(pageCount)}
              style={{ display: current >= pageCount - 1 ? 'none' : 'flex' }}
              isSquare
            >
              {pageCount}
            </Button>

            <Button
              variant='text-neutral'
              disabled={current === pageCount}
              onClick={() => handlePageChange(current + 1)}
              isSquare
              data-testid='next-button'
            >
              <Icon icon='icon-caret-right' />
            </Button>

            <Button
              variant='text-neutral'
              disabled={current === pageCount}
              onClick={() => handlePageChange(pageCount)}
            >
              {t('pagination.word.last')}
            </Button>
          </Row>
        ) : null}

        <Row alignItems='center'>
          <PageSize
            handlePageSizeChange={handlePageSizeChange}
            pageSize={pageSize}
          />

          <Spreader spread={15} />
        </Row>
      </Row>

      <Spacer space={10} />
    </Fragment>
  )
}
