import { EDITOR_DOMAIN } from '@config/env'
import { openWindowWithJWT } from '@helpers/semrush'
import { useLazyService } from '@hooks/useLazyService'
import { SMART_SECTIONS } from '@routes/path'
import { createSmartSection } from '@services/smartsections/create'
import {
  Button,
  Heading,
  Input,
  Paragraph,
  Radio,
  Spacer,
  Spreader,
  Text
} from '@ui-kit'
import { Close } from '@ui-kit/Close'
import { useFormik } from 'formik'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Column, Row } from 'simple-flexbox'
import { v4 as uuidv4 } from 'uuid'

import styles from './Create.module.scss'
import validationSchema from './validationSchema'

export const CreateSmartSection = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const close = () => navigate(SMART_SECTIONS.SMART_SECTIONS)

  const [createSmartSectionRequest, { isLoading }] = useLazyService(
    createSmartSection,
    {
      handleErrorMessages: true,
      t,
      forwardError: true
    }
  )

  const { values, handleChange, handleSubmit, isValid, dirty } = useFormik({
    validationSchema,
    initialValues: { smartSectionName: '', width: '960' },
    onSubmit: async () => {
      const uuid = uuidv4()

      try {
        await createSmartSectionRequest({
          uuid,
          width: parseInt(values.width, 10),
          name: values.smartSectionName
        })

        openWindowWithJWT(`${EDITOR_DOMAIN}/smart-section/${uuid}`)
      } catch {
        // do nothing
        // error message is handled by useLazyService
        // try-catch is needed to handle situation, when request fails to not open editor
        // request can fail, eg. when user selects name that already exists
      }
    }
  })

  return (
    <motion.div
      initial={{ x: '-100vw' }}
      animate={{ x: 0 }}
      exit={{ x: '-100vw' }}
      transition={{ ease: [0.37, 0, 0.39, 1], duration: 0.85 }}
    >
      <Row>
        <Close onClick={close} className={styles.close} />

        <Column className={styles.container}>
          <form onSubmit={handleSubmit}>
            <Heading level={1}>{t('modal.smartsection.create.title')}</Heading>

            <Spacer space={25} />

            <Input
              size={16}
              value={values.smartSectionName}
              onChange={handleChange}
              id='smartSectionName'
              autoFocus
              i18n={{
                label: t('modal.smartsection.create.name.label')
              }}
            />

            <Spacer space={30} />

            <Paragraph size={14}>
              {t('modal.smartsection.create.width.label')}
            </Paragraph>

            <Spacer space={10} />

            <Row>
              <Radio
                field={{
                  name: 'width',
                  value: values.width,
                  onChange: handleChange
                }}
                label={<Text size={14}>960px</Text>}
                id='960'
              />

              <Spreader spread={50} />

              <Radio
                field={{
                  name: 'width',
                  value: values.width,
                  onChange: handleChange
                }}
                label={<Text size={14}>1200px</Text>}
                id='1200'
              />
            </Row>

            <Row justifyContent='flex-end'>
              <Button
                type='submit'
                disabled={!isValid || !dirty || isLoading}
                isLoading={isLoading}
              >
                {t('modal.smartsection.create.width.button')}
              </Button>
            </Row>
          </form>
        </Column>
      </Row>
    </motion.div>
  )
}
