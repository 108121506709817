import { ADDONS_IDS } from '@constants/addonsIds'
import { domainWhitelistingPrices } from '@constants/addonsPrices'
import { ADDONS_UUIDS } from '@constants/addonsUuids'
import DomainWhitelisting from '@images/addons/icons/DomainWhitelisting.svg'
import {
  FEATURE,
  NEW_NO_ICON
} from '@pages/AddonsStore/routes/Addons/components/Badges/badges'
import { IpDomainWhitelistingSecondaryButton } from '@pages/AddonsStore/routes/Addons/components/Buttons/SecondaryButton'
import { REQUEST, RequestAddonTemplate } from '@pages/AddonsStore/routes/types'
import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'

import { getFAQ } from './FAQ'
import { getFeatures } from './Features'
import { getOverview } from './Overview'
import { getPricing } from './Pricing'
import { getUseCases } from './UseCases'

export const getIpDomainWhitelistingAddon: (
  t: TFunction<'translation', undefined>
) => RequestAddonTemplate = t => ({
  type: REQUEST,
  name: t('addons.store.ip.domain.whitelisting.name'),
  title: t('addons.store.ip.domain.whitelisting.name'),
  tileDescription: (
    <Trans i18nKey='addons.store.ip.domain.whitelisting.description' />
  ),
  badges: [NEW_NO_ICON, FEATURE],
  imageSrc: DomainWhitelisting,
  id: ADDONS_IDS.DOMAIN_WHITELISTING,
  uuid: ADDONS_UUIDS.DOMAIN_WHITELISTING,
  pageDescription: t('addons.store.ip.domain.whitelisting.page.description'),
  overview: getOverview(t),
  features: getFeatures(),
  price: domainWhitelistingPrices,
  pricing: getPricing(),
  customPriceDescription: t(
    'ip.domain.whitelisting.request.pricing.description'
  ),
  useCases: getUseCases(t),
  faq: getFAQ(t),
  secondaryButton: IpDomainWhitelistingSecondaryButton
})
