import { useModal } from '@ebay/nice-modal-react'
import { useLazyService } from '@hooks/useLazyService'
import { Button, Input, Spacer, Spreader } from '@landingi/landingi-ui-kit'
import { renameLanding, RenameLandingRequest } from '@services/landings/rename'
import { useFormik } from 'formik'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Column, Row } from 'simple-flexbox'
import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

interface ModalLandingRenameFormProps {
  name: string
  uuid: string
  callback: (name: string) => void
}

export const ModalLandingRenameForm: FC<ModalLandingRenameFormProps> = ({
  name,
  uuid,
  callback
}) => {
  const { t } = useTranslation()
  const { remove } = useModal()

  const [handleRenameLanding, { isLoading }] =
    useLazyService<RenameLandingRequest>(renameLanding, {
      successToastText: t('toast.saved.success'),
      onSuccess: (res, { newName }) => {
        callback(newName)

        remove()
      },
      handleErrorMessages: true,
      t
    })

  const initialValues = {
    name
  }

  const validationSchema = toFormikValidationSchema(
    z.object({
      name: z
        .string({
          required_error: t('form.validation.isRequired')
        })
        .refine(value => value !== name)
    })
  )

  const { isSubmitting, handleSubmit, dirty, isValid, handleChange, values } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async values => {
        const { name } = values

        await handleRenameLanding({ uuid, newName: name })
      }
    })

  return (
    <form onSubmit={handleSubmit}>
      <Row wrap>
        <Column flex='1'>
          <Input
            name='name'
            defaultValue={name}
            i18n={{
              placeholder: t('label.name'),
              label: t('modal.landing.rename.label')
            }}
            onChange={handleChange}
            value={values.name}
            autoFocus
          />
        </Column>
      </Row>

      <Spacer />

      <Row flex='1' justifyContent='end'>
        <Row>
          <Button variant='secondary' size='medium' onClick={remove}>
            {t('label.cancel')}
          </Button>

          <Spreader spread='small' />

          <Button
            size='medium'
            onClick={() => handleSubmit()}
            isLoading={isSubmitting || isLoading}
            isDisabled={!dirty || !isValid || isSubmitting}
          >
            {t('label.save')}
          </Button>
        </Row>
      </Row>
    </form>
  )
}

ModalLandingRenameForm.displayName = 'ModalLandingRenameForm'
