export const ADDONS_UUIDS = {
  EVENT_TRACKER: '1e970602-73a8-491e-a91c-318ccf54285a',
  EVENT_TRACKER_PERCENT: 'a9dbbc16-ebe4-4f18-88ae-b12d365b2b74',
  SMART_SECTIONS: 'ac193f96-c4eb-4e42-ae36-a1b70fb208c2',
  OPTIMIZATION_ADDON: 'a15f2b6a-93be-4b37-a6d5-ea88518e7496',
  ECOMMERCE_ADDON: 'fb13f951-8b9b-4ccf-a6c6-a51580e33920',
  POPUPS_ADDON: 'ce37bc22-c94b-4a1d-8318-4f233076dc41',
  DOMAIN_WHITELISTING: 'TODO'
} as const

export type AddonID = (typeof ADDONS_UUIDS)[keyof typeof ADDONS_UUIDS]
