import { Badge, Tooltip } from '@landingi/landingi-ui-kit'
import { ItemBase } from '@ui-kit/Table/types'
import { Trans } from 'react-i18next'

import { PublicationType } from './components/PublicationType'
import { UrlCell } from './components/UrlCell'

export const columns = [
  {
    id: 'publicationType',
    render: ({ publicationType }: ItemBase) => (
      <PublicationType publicationType={publicationType} />
    ),
    name: <Trans i18nKey='word.domain.type' />,
    width: '200px'
  },
  {
    id: 'badge',
    render: ({ isMain }: ItemBase) =>
      isMain ? (
        <Tooltip
          content={<Trans i18nKey='publishing.options.main.url' />}
          placement='top'
        >
          <Badge type='accent-5'>
            <Trans i18nKey='word.main' />
          </Badge>
        </Tooltip>
      ) : null,
    name: '',
    width: '100px'
  },
  {
    id: 'url',
    render: ({ url, isFake, publicationType }: ItemBase) => (
      <UrlCell url={url} isFake={isFake} publicationType={publicationType} />
    ),
    name: <Trans id='smart.section.landing.page.list.url' />,
    width: '510px'
  }
]
