import { Button } from '@ui-kit/Button'
import { ColorPicker } from '@ui-kit/ColorPicker'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps
} from '@ui-kit/Dialog'
import { RGBColor } from '@ui-kit/types/color'
import { FC, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import styles from './ColorPickerDialog.module.scss'

export interface ColorPickerDialogProps
  extends Omit<
    DialogProps,
    'renderTrigger' | 'isOpen' | 'children' | 'onClose'
  > {
  colorValue: RGBColor
  includeAlpha?: boolean
  onColorChange: (newColor: RGBColor) => void
}

export const ColorPickerDialog: FC<ColorPickerDialogProps> = ({
  colorValue,
  includeAlpha = true,
  onColorChange,
  placement = 'right-center',
  ...restProps
}) => {
  const { t } = useTranslation()
  const prevSettings = useRef(colorValue)
  const [isOpen, setOpen] = useState(false)
  const [isColorChanging, setColorChanging] = useState(false)
  const [isPixelColorSelecting, setPixelColorSelecting] = useState(false)

  const finishColorChange = useCallback(() => {
    setColorChanging(false)
  }, [])

  const startColorChange = useCallback(() => setColorChanging(true), [])

  const finishPixelColorSelecting = useCallback(
    () => setPixelColorSelecting(false),
    []
  )

  const startPixelColorSelecting = useCallback(
    () => setPixelColorSelecting(true),
    []
  )

  const handleCancel = () => {
    /**
     * Prevents closing the dialog when the color is changing
     */
    if (isColorChanging) {
      finishColorChange()
      return
    }

    setOpen(false)
    onColorChange(prevSettings.current)
  }

  const handleSave = () => setOpen(false)

  const handleOpen = () => {
    setOpen(true)
    prevSettings.current = colorValue
  }

  return (
    <Dialog
      {...restProps}
      className={styles.colorPickerDialog}
      placement={placement}
      isOpen={isOpen}
      onClose={handleCancel}
      moveToPlacementOnClose={!isColorChanging}
      onPointerUpOnLayer={finishColorChange}
      renderTrigger={
        <Button
          data-testid='color-picker-trigger'
          icon='icon-create'
          size={14}
          variant='text-neutral'
          onClick={handleOpen}
        />
      }
      isClosableOnClickOutside={!isPixelColorSelecting}
    >
      <DialogContent>
        <ColorPicker
          includeAlpha={includeAlpha}
          colorValue={colorValue}
          onChange={onColorChange}
          onStartColorChange={startColorChange}
          onStartPixelColorSelecting={startPixelColorSelecting}
          onFinishPixelColorSelecting={finishPixelColorSelecting}
        />
      </DialogContent>

      <DialogActions
        variant='border-footer'
        secondaryAction={{
          i18nTitle: t('label.cancel'),
          onClick: handleCancel
        }}
        primaryAction={{ i18nTitle: t('word.save'), onClick: handleSave }}
      />
    </Dialog>
  )
}

ColorPickerDialog.displayName = 'ColorPickerDialog'
