import { getLocalStorage, setLocalStorage } from '@helpers/storage'
import { useUpdateEffect } from '@hooks/useUpdateEffect'
import { useGetAnyProducts } from '@services/products/useGetAnyProducts'
import {
  GetProductsResponse,
  SorterTypes,
  useGetProducts
} from '@services/products/useGetProducts'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react'

import {
  LOCAL_STORAGE_KEY_PAGE_LIMIT,
  LOCAL_STORAGE_KEY_SORTER
} from '../constants'

type FetchParamsTypes = {
  search: string
  sort_field: SorterTypes
  page: number
  limit: number
}

interface ProductsContextValue {
  productsAnyData: boolean
  isLoadingAnyProduct: boolean
  productsData: GetProductsResponse | undefined
  isLoadingProduct: boolean
  selectedProduct: string[]
  setSelectedProduct: Dispatch<SetStateAction<string[]>>
  fetchParams: FetchParamsTypes
  onSearchParamChange: (search: string) => void
  onSorterChange: (sort_field: SorterTypes) => void
  onPageChange: (page: number) => void
  onPageSizeChange: (limit: number) => void
  refreshTableWithQueryParams: () => void
}

const ProductsContext = createContext<ProductsContextValue | null>(null)

export const ProductsProvider = ({ children }: { children: ReactNode }) => {
  const [selectedProduct, setSelectedProduct] = useState<string[]>([])
  const [fetchParams, setFetchParams] = useState<FetchParamsTypes>({
    search: '',
    sort_field:
      (getLocalStorage(LOCAL_STORAGE_KEY_SORTER) as SorterTypes) ||
      'created_at',
    page: 1,
    limit: Number(getLocalStorage(LOCAL_STORAGE_KEY_PAGE_LIMIT)) || 10
  })

  const {
    data: productsAnyData,
    isLoading: isLoadingAnyProduct,
    mutate: mutateAnyProducts
  } = useGetAnyProducts()

  const {
    data: productsData,
    isLoading: isLoadingProduct,
    mutate: mutateProducts
  } = useGetProducts(fetchParams)

  const refreshTableWithQueryParams = useCallback(async () => {
    if (
      !productsData?.collection.length ||
      !productsAnyData?.collection.length
    ) {
      mutateAnyProducts(undefined, { revalidate: true })
    }

    mutateProducts(undefined, { revalidate: true })

    setSelectedProduct([])
  }, [mutateProducts, mutateAnyProducts, productsAnyData, productsData])

  const onSearchParamChange = (search: string) =>
    setFetchParams(prev => ({ ...prev, page: 1, search }))

  const onSorterChange = (sort_field: SorterTypes) =>
    setFetchParams(prev => ({ ...prev, sort_field }))

  const onPageChange = (page: number) =>
    setFetchParams(prev => ({ ...prev, page }))

  const onPageSizeChange = (limit: number) =>
    setFetchParams(prev => ({ ...prev, limit }))

  useUpdateEffect(() => {
    setLocalStorage(LOCAL_STORAGE_KEY_SORTER, fetchParams.sort_field)
    setLocalStorage(LOCAL_STORAGE_KEY_PAGE_LIMIT, fetchParams.limit)
  }, [fetchParams])

  const ctxValue = useMemo(
    () => ({
      productsAnyData: !!productsAnyData?.collection.length,
      isLoadingAnyProduct,
      productsData,
      isLoadingProduct,
      selectedProduct,
      setSelectedProduct,
      fetchParams,
      onSearchParamChange,
      onSorterChange,
      onPageChange,
      onPageSizeChange,
      refreshTableWithQueryParams
    }),
    [
      productsAnyData,
      isLoadingAnyProduct,
      productsData,
      isLoadingProduct,
      selectedProduct,
      setSelectedProduct,
      fetchParams,
      refreshTableWithQueryParams
    ]
  )

  return (
    <ProductsContext.Provider value={ctxValue}>
      {children}
    </ProductsContext.Provider>
  )
}

export const useProductsContext = () => {
  const ctxValue = useContext(ProductsContext)

  if (!ctxValue) {
    throw new Error('useProductsContext must be used inside ProductsProvider')
  }

  return ctxValue
}
