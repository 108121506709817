import DomainWhitelistingOverview from '@images/addons/overview/DomainWhitelisting-overview.png'
import { Image } from '@ui-kit'
import { TFunction } from 'i18next'

export const getOverview = (t: TFunction<'translation', undefined>) => ({
  heading: t('addons.store.ip.domain.whitelisting.overview.title'),
  leftSide: (
    <Image src={DomainWhitelistingOverview} height='360px' width='640px' />
  ),
  list: [
    t('addons.store.ip.domain.whitelisting.overview.list.1'),
    t('addons.store.ip.domain.whitelisting.overview.list.2'),
    t('addons.store.ip.domain.whitelisting.overview.list.3')
  ]
})
