import client from '@lib/http/client'
import { AxiosError } from 'axios'
import { useState } from 'react'
import useSWRImmutable from 'swr/immutable'
import useSWRInfinite from 'swr/infinite'

export interface AccountListRequest {
  page?: number
  limit?: number
  filters?: {
    without_current_account?: boolean
    only_main_account?: boolean
    without_main_account?: boolean
    without_banned_accounts?: boolean
    name?: string
  }
}

export type Account = {
  identifier: string
  name: string
  type: string
  isBanned: boolean
}

interface AccountsListResponse {
  collection: Account[]
  pagination: {
    counter: {
      current: number
      total: number
    }
  }
}

export const useGetAccountsList = ({
  page = 1,
  limit = 10,
  filters = undefined
}: AccountListRequest = {}) => {
  const searchParams = new URLSearchParams({
    ...(page ? { page: String(page) } : {}),
    ...(limit ? { limit: String(limit) } : {})
  })

  if (filters) {
    Object.entries(filters).forEach(([key, value]) => {
      searchParams.append(`filters[${key}]`, String(value))
    })
  }

  return useSWRImmutable<AccountsListResponse, AxiosError>(
    `accounts/get-accounts-managed-by-current-user?${searchParams.toString()}`
  )
}

type AccountListInfiniteRequest = Omit<AccountListRequest, 'page'>

export const useGetAccountsListInfinite = ({
  filters
}: AccountListInfiniteRequest) => {
  const [total, setTotal] = useState<number>(0)

  const getKey = (page: number) => {
    const searchParams = new URLSearchParams({
      ...(page ? { page: String(page + 1) } : {})
    })

    if (filters) {
      Object.entries(filters).forEach(([key, value]) => {
        if (value) {
          searchParams.append(`filters[${key}]`, String(value))
        }
      })
    }

    return `accounts/get-accounts-managed-by-current-user?${searchParams.toString()}`
  }

  return {
    ...useSWRInfinite<Account[]>(getKey, (url: string) =>
      client.get(url).then(res => {
        if (res.data.pagination) {
          setTotal(res.data.pagination.counter.total)
        }

        return res.data.collection
      })
    ),
    total
  }
}

export const changeUserAccount = (uuid: string) =>
  client.post('users/current/switch-account', {
    account_uuid: uuid
  })

export const addSubaccount = ({
  uuid,
  name,
  lp_limit
}: {
  uuid: string
  name: string
  lp_limit: number
}) =>
  client.post('agency/subaccounts', {
    uuid,
    name,
    lp_limit
  })

export const editSubaccount = ({
  uuid,
  name,
  lp_limit
}: {
  uuid: string
  name: string
  lp_limit: number
}) =>
  client.post(`agency/subaccounts/${uuid}`, {
    uuid,
    name,
    lp_limit
  })

export const toggleSubaccountActivationStatus = (uuid: string) =>
  client.patch(`agency/subaccounts/${uuid}/status`)

export const removeSubaccount = (uuid: string) =>
  client.delete(`agency/subaccounts/${uuid}`)
