import { useAcl } from '@contexts/acl'
import { SecondaryButtonSize } from '@pages/AddonsStore/routes/types'
import { Button } from '@ui-kit'
import i18next from 'i18next'
import { ReactNode } from 'react'

export const SecondaryButton = ({
  children,
  handleClick,
  size = 16
}: {
  children: ReactNode
  handleClick: () => void
  size?: SecondaryButtonSize
}) => {
  const hasAccessToManageAddons = useAcl('payments.product_add_on.management')

  return (
    <Button
      size={size}
      onClick={handleClick}
      disabled={!hasAccessToManageAddons}
      variant='text-primary'
    >
      {children}
    </Button>
  )
}

export const EventTrackerScheduleDemoButton = (size?: SecondaryButtonSize) => (
  <SecondaryButton
    size={size}
    handleClick={() =>
      window.open(i18next.t('addons.store.schedule.event.tracker.demo'))
    }
  >
    {i18next.t('addons.store.schedule.demo')}
  </SecondaryButton>
)

export const SmartSectionsScheduleDemoButton = (size?: SecondaryButtonSize) => (
  <SecondaryButton
    size={size}
    handleClick={() =>
      window.open(i18next.t('addons.store.smart.sections.schedule.demo'))
    }
  >
    {i18next.t('addons.store.schedule.demo')}
  </SecondaryButton>
)

export const ABTestsScheduleDemoButton = (size?: SecondaryButtonSize) => (
  <SecondaryButton
    size={size}
    handleClick={() =>
      window.open(i18next.t('addons.store.ab.tests.schedule.demo'))
    }
  >
    {i18next.t('addons.store.schedule.demo')}
  </SecondaryButton>
)

export const PopupsScheduleDemoButton = (size?: SecondaryButtonSize) => (
  <SecondaryButton
    size={size}
    handleClick={() =>
      window.open(i18next.t('addons.store.popups.schedule.demo'))
    }
  >
    {i18next.t('addons.store.schedule.demo')}
  </SecondaryButton>
)

export const EcommerceScheduleDemoButton = (size?: SecondaryButtonSize) => (
  <SecondaryButton
    size={size}
    handleClick={() =>
      window.open(i18next.t('addons.store.e.commerce.schedule.demo'))
    }
  >
    {i18next.t('addons.store.schedule.demo')}
  </SecondaryButton>
)

export const LandingPageImportSecondaryButton = (
  size?: SecondaryButtonSize
) => (
  <SecondaryButton
    size={size}
    handleClick={() =>
      window.open(i18next.t('addons.store.landing.page.import.expert.link'))
    }
  >
    {i18next.t('addons.store.talk.with.expert')}
  </SecondaryButton>
)

export const LandingPageDesignSecondaryButton = (
  size?: SecondaryButtonSize
) => (
  <SecondaryButton
    size={size}
    handleClick={() =>
      window.open(i18next.t('addons.store.landing.page.design.expert.link'))
    }
  >
    {i18next.t('addons.store.talk.with.expert')}
  </SecondaryButton>
)

export const AgencyScheduleDemoButton = (size?: SecondaryButtonSize) => (
  <SecondaryButton
    size={size}
    handleClick={() =>
      window.open(i18next.t('addons.store.schedule.agency.demo'))
    }
  >
    {i18next.t('addons.store.schedule.demo')}
  </SecondaryButton>
)

export const IpDomainWhitelistingSecondaryButton = (
  size?: SecondaryButtonSize
) => (
  <SecondaryButton
    size={size}
    handleClick={() =>
      window.open(i18next.t('addons.store.ip.domain.whitelisting.expert.link'))
    }
  >
    {i18next.t('addons.store.talk.with.expert')}
  </SecondaryButton>
)
