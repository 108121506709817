import client from '@lib/http/client'

export interface TranslateLandingRequest {
  landingUuid: string
  name: string
  sourceLanguage: string
  desiredLanguage: string
}

export interface TranslateLandingResponse {
  data: {
    total_chars_translated: number
    estimated_cost: string
    hash: string
    uuid: string
  }
}

export const translateLanding = ({
  landingUuid,
  name,
  sourceLanguage,
  desiredLanguage
}: TranslateLandingRequest) =>
  client.post(
    `landing-page/landing/${landingUuid}/translate-and-duplicate`,
    {
      source_lang: sourceLanguage,
      target_lang: desiredLanguage,
      name
    },
    {
      timeout: 60000
    }
  )
