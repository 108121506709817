import { badges as availableBadges } from '@pages/AddonsStore/routes/Addons/components/Badges/badges'
import { ReactNode } from 'react'

export const CREDITS_PRICING = 'CREDITS_PRICING' as const
export const PAID_PRICING = 'PAID_PRICING' as const

export type AddonOverview = {
  heading: ReactNode
  leftSide: ReactNode
  list: ReactNode[]
}

export type AddonPricing = {
  list: ReactNode[]
  billedAnnuallyTooltip?: string
}

export type AIAddonPricing = {
  list: ReactNode[]
  billedAnnuallyTooltip?: string
  whatsPriceFor: string
  tooltip?: string
}

export type Feature = {
  title: ReactNode
  description: ReactNode
  icon: ReactNode
}

export type Service = {
  title: ReactNode
  description: ReactNode
  icon: ReactNode
}

export type AddonUseCases = {
  title: ReactNode
  content: ReactNode
  reverse?: boolean
  image: string
}

export type AddonFAQ = {
  title: ReactNode
  content: ReactNode
}

interface Price {
  1: {
    USD: number
    PLN: number
    BRL: number
    EUR: number
    GBP: number
  }
  12: {
    USD: number
    PLN: number
    BRL: number
    EUR: number
    GBP: number
  }
}

interface MonthlyPrice {
  1: {
    USD: number
    PLN: number
    BRL: number
    EUR: number
    GBP: number
  }
}

interface OneTimePrice {
  USD: number
  PLN: number
  BRL: number
  EUR: number
  GBP: number
}

export const BUYABLE = 'buyable'
export const REQUEST = 'request'
export const POC_TEST = 'tested'
export const AI = 'ai'
export const SERVICE = 'service'
export const ADVERTISE = 'advertise'

export type SecondaryButtonSize = 14 | 16

export interface AddonTemplate {
  name: string
  title: string
  tileDescription: ReactNode
  pageDescription: ReactNode
  badges: (keyof typeof availableBadges)[]
  imageSrc: string
  id: string
  secondaryButton?: (size?: SecondaryButtonSize) => JSX.Element
  limitedInPlans?: string[]
}

// addon that is available to buy
export interface BuyableAddonTemplate extends AddonTemplate {
  type: typeof BUYABLE
  urlToLP?: string
  uuid: string
  overview: AddonOverview
  features: Feature[]
  price?: Price
  pricing?: AddonPricing
  useCases?: AddonUseCases[]
  faq?: AddonFAQ[]
  featureFlag?: string
  localStorageKey?: string
  isBeingTested?: boolean
  isHidden?: boolean
}

export interface RequestAddonTemplate extends AddonTemplate {
  type: typeof REQUEST
  uuid: string
  overview: AddonOverview
  features: Feature[]
  price?: MonthlyPrice
  pricing?: AddonPricing
  customPriceDescription?: string
  useCases?: AddonUseCases[]
  faq?: AddonFAQ[]
  featureFlag?: string
  isHidden?: boolean
}

// addon whose demand is being tested
export interface PocTestAddonTemplate extends AddonTemplate {
  type: typeof POC_TEST
  urlToLP?: string
  uuid?: string
  overview: AddonOverview
  features: Feature[]
  price?: MonthlyPrice
  pricing?: AddonPricing
  useCases?: AddonUseCases[]
  faq?: AddonFAQ[]
  featureFlag?: string
  localStorageKey?: string
  isBeingTested?: boolean
  isHidden?: boolean
}

// AI addon that requires credits to be used
export interface AIAddonTemplate extends AddonTemplate {
  type: typeof AI
  urlToLP?: string
  uuid?: string
  overview: AddonOverview
  features: Feature[]
  price: string
  pricing: AIAddonPricing
  useCases?: AddonUseCases[]
  faq?: AddonFAQ[]
  featureFlag?: string
  localStorageKey?: string
  isBeingTested?: boolean
  isHidden?: boolean
  CTALink: string
  customButtonLabel?: string
}

// Addon of type service, like design service etc.
export interface ServiceAddonTemplate extends AddonTemplate {
  type: typeof SERVICE
  urlToLP?: string
  uuid?: string
  overview: AddonOverview
  services: Service[]
  price?: OneTimePrice
  pricing?: AddonPricing
  useCases?: AddonUseCases[]
  faq?: AddonFAQ[]
  featureFlag?: string
  localStorageKey?: string
  isBeingTested?: boolean
  isHidden?: boolean
}

// Advertise addon that is used to advertise the product / plan, without any pricing
export interface AdvertiseAddonTemplate extends AddonTemplate {
  type: typeof ADVERTISE
  overview: AddonOverview
  features: Feature[]
  useCases?: AddonUseCases[]
  faq?: AddonFAQ[]
  featureFlag?: string
  isHidden?: boolean
  CTALink: string
  uuid?: string
}

export type AddonTemplateTypes =
  | BuyableAddonTemplate
  | PocTestAddonTemplate
  | AIAddonTemplate
  | ServiceAddonTemplate
  | AdvertiseAddonTemplate
  | RequestAddonTemplate
