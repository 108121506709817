import { MODAL_UPGRADE_PLAN } from '@components/Modals/ModalUpgradePlan'
import NiceModal from '@ebay/nice-modal-react'
import { Badge } from '@landingi/landingi-ui-kit'
import { FC, Fragment } from 'react'
import { NavLink } from 'react-router-dom'

import styles from './ItemGroup.module.scss'

interface ItemGroupProps {
  identifier?: string
  text: string
  to?: string
  href?: string
  onClick?: () => void
  counterValue?: number
  hasAccessToPage?: boolean
  upgradeType?: string
}

export const ItemGroup: FC<ItemGroupProps> = ({
  identifier,
  text,
  to,
  href,
  onClick,
  counterValue,
  hasAccessToPage = true,
  upgradeType
}) => {
  const checkIsActive = ({ isActive = false }) =>
    isActive
      ? `${styles['item-group']} ${styles['item-group--active']}`
      : styles['item-group']

  const Indicator = counterValue ? (
    <Badge type='alert' isIndicator>
      {counterValue}
    </Badge>
  ) : null

  const handleOpenUpgradeModal = () =>
    NiceModal.show(MODAL_UPGRADE_PLAN, {
      upgradeType
    })

  return (
    <Fragment>
      {!hasAccessToPage && to && (
        <div
          id={identifier}
          className={styles['item-group']}
          onClick={handleOpenUpgradeModal}
          data-testid='nav-item-group'
        >
          <span className={styles['item-group__content']}>
            {text} {Indicator}
          </span>
        </div>
      )}

      {!href && !onClick && to && hasAccessToPage && (
        <NavLink
          id={identifier}
          to={to}
          className={checkIsActive}
          data-testid='nav-item-group'
        >
          <span className={styles['item-group__content']}>
            {text} {Indicator}
          </span>
        </NavLink>
      )}

      {(href || onClick) && !to && (
        <a
          id={identifier}
          className={styles['item-group']}
          data-testid='nav-item-group-link'
          href={href}
          onClick={onClick}
        >
          <span className={styles['item-group__content']}>
            {text} {Indicator}
          </span>
        </a>
      )}
    </Fragment>
  )
}

ItemGroup.displayName = 'SidebarItemGroup'
