import { useGetAuth } from '@services/authentication'
import { AxiosError } from 'axios'
import { useCallback } from 'react'
import useSWRImmutable from 'swr/immutable'

type FeatureFlagsData = string[]

export const useGetFeatureFlags = () => {
  const { isAuth } = useGetAuth()

  const { data, isLoading, ...rest } = useSWRImmutable<
    FeatureFlagsData,
    AxiosError
  >(isAuth ? `acl/access/features` : null)

  const checkAccessToFeature = useCallback(
    (featureKey: string) => data?.includes(featureKey),
    [data]
  )

  return {
    data,
    checkAccessToFeature,
    isLoading,
    ...rest
  }
}
