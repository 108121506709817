import { PROD } from '@config/env'
import { useFeatureFlags } from '@contexts/featureFlags'
import { getLocalStorage } from '@helpers/storage'
import Hotjar from '@hotjar/browser'
import { useGetHotjarInfo } from '@services/webAnalytics'
import { useEffect } from 'react'

export const useHotjar = () => {
  const { data } = useGetHotjarInfo()

  const hasAccessToEventTracker = useFeatureFlags('EVENT_TRACKER')

  const event_tracker = hasAccessToEventTracker ? '1' : '0'

  const used_translation = !!getLocalStorage('used_translation')

  const used_wizard = !!getLocalStorage('used_wizard')

  useEffect(() => {
    if (PROD && data) {
      const { country, identifier, is_trial, record_key } = data.account

      Hotjar.identify(identifier, {
        account_uuid: identifier,
        record_key,
        country,
        free_trial: is_trial,
        event_tracker,
        used_translation,
        used_wizard
      })
    }
  }, [data, event_tracker, used_translation, used_wizard])
}
