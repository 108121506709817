import { CurrencyType } from '@constants/currencies'
import { AxiosError } from 'axios'
import useSWRImmutable from 'swr/immutable'

export interface Price {
  amount_net: number
  currency: CurrencyType
}

export type PackagesResponse = {
  name: string
  amount: number
  price: Price
}[]

export const useGetCreditsPackage = () => {
  const response = useSWRImmutable<PackagesResponse, AxiosError>(
    'credits/packages'
  )
  const data = response.data?.[0]

  return data
}
