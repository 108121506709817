import { useLazyService } from '@hooks/useLazyService'
import {
  Button,
  Heading,
  Html,
  Icon,
  Image,
  Spacer
} from '@landingi/landingi-ui-kit'
import { useGetAccountInfo } from '@services/account'
import { useGetProductUpdates } from '@services/productUpdates'
import { updateLastSeen } from '@services/productUpdates/productUpdates'
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './Popup.module.scss'

export const Popup = () => {
  const { t } = useTranslation()
  const { language } = i18next

  const { emphasizedNotificationData, emphasizedNotificationMutate } =
    useGetProductUpdates()

  const { createdAt } = useGetAccountInfo()

  const notification = emphasizedNotificationData?.notification

  const content =
    notification?.[`content_${language}` as 'content_en' | 'content_pl'] ??
    notification?.content_en

  const currentDate = new Date().toISOString().slice(0, 10)

  const [handleUpdateLastSeen] = useLazyService(updateLastSeen)

  const handleClosePopup = async () => {
    await handleUpdateLastSeen(currentDate)

    emphasizedNotificationMutate({ notification: null })
  }

  const publishedAtDate = notification?.published_at

  const accountCreatedAtDate = createdAt?.formatted

  if (!publishedAtDate || !accountCreatedAtDate) return null

  const isDateOfNotificationLater = (
    dateOfNotification: string,
    dateOfRegistration: string
  ): boolean => {
    return new Date(dateOfNotification) > new Date(dateOfRegistration)
  }

  const shouldRender =
    notification &&
    isDateOfNotificationLater(publishedAtDate, accountCreatedAtDate)

  return shouldRender ? (
    <div className={styles.wrapper} data-testid='product-popup'>
      <Row
        justifyContent='space-between'
        alignItems='center'
        className={styles.wrapper__top}
      >
        <Heading level={4} bold margin='none'>
          {content?.title}
        </Heading>

        <Button
          variant='icon'
          onClick={handleClosePopup}
          data-testid='close-button'
        >
          <Icon icon='icon-remove' />
        </Button>
      </Row>

      <Spacer space='small' />

      <Spacer space='mini' />

      {content?.img_url && (
        <Image className={styles.wrapper__img} src={content?.img_url} />
      )}

      <Spacer space='tiny' />

      <Spacer space='mini' />

      <div className={styles.wrapper__textarea}>
        <Html value={content?.description} />
      </div>

      <Spacer space='small' />

      <Spacer space='mini' />

      <Button
        size='tiny'
        tag='a'
        target='_blank'
        variant='primary'
        href={content?.link}
        buttonStyle
      >
        {content?.button_label || t('word.learn.more')}
      </Button>
    </div>
  ) : null
}

Popup.displayName = 'ProductUpdatesPopup'
