import { CurrenciesType } from '@constants/currencies'
import { newLandingiClient } from '@lib/http/client'
import { AxiosError } from 'axios'
import useSWR from 'swr'

export type SorterTypes = 'price' | '-price' | 'created_at' | '-created_at'

interface GetProductsRequest {
  search?: string
  page: number
  limit: number
  sort_field: SorterTypes
}

interface GetProductsFeetcherProps extends GetProductsRequest {
  url: string
}

export interface GetProductsItem {
  id: string
  account_id: string
  name: string
  price: number
  currency: CurrenciesType
  created_at: {
    date: string
    timezone_type: number
    timezone: string
  }
  updated_at: {
    date: string
    timezone_type: number
    timezone: string
  }
}

export interface GetProductsResponse {
  collection: GetProductsItem[]
  pagination: {
    filters: {
      search: string | null
      page: number
      limit: number
      sort_field: string
      sort_direction: string
    }
    counter: {
      current: number
      total: number
    }
  }
}

const getProducts = async ({
  url,
  search,
  page,
  limit,
  sort_field
}: GetProductsFeetcherProps) => {
  const replaceMinusFromFilterString = sort_field.replace('-', '')

  const sort_direction = sort_field.includes('-') ? 'DESC' : 'ASC'

  const params = {
    ...(search ? { search } : {}),
    page,
    limit,
    sort_field: replaceMinusFromFilterString,
    sort_direction
  }

  const response = await newLandingiClient.get<GetProductsResponse>(url, {
    params
  })

  return response.data
}

export const useGetProducts = ({
  search,
  page,
  limit,
  sort_field
}: GetProductsRequest) =>
  useSWR<GetProductsResponse, AxiosError>(
    [`/ecommerce/v1/product`, search, page, limit, sort_field],
    ([url, search, page, limit, sort_field]: [
      string,
      string | undefined,
      number,
      number,
      SorterTypes
    ]) => getProducts({ url, search, page, limit, sort_field }),
    {}
  )
