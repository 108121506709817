import { AxiosError } from 'axios'
import useSWRImmutable from 'swr/immutable'

export interface LanguageDto {
  language: string
  name: string
}

export interface GetLanguagesResponse {
  sourceLanguages: LanguageDto[]
  targetLanguages: LanguageDto[]
}

export const useGetLanguages = () =>
  useSWRImmutable<GetLanguagesResponse, AxiosError>(
    `content-generation/translation-languages`
  )
