import { AxiosError } from 'axios'
import useSWRImmutable from 'swr/immutable'

export type AccountLimitsResponse = {
  landingPages?: {
    landingPagePublicationCount: number
    landingPagePublicationLimit: number
  }
  uniqueVisits?: {
    uniqueVisitsCount: number
    uniqueVisitsLimit: number
    regularLimit: number
  }
  uniqueUsers?: {
    uniqueUsersCount: number
    uniqueUsersLimit: number
  }
  domains?: {
    activeOwnDomainsCount: number
    ownDomainsCount: number
    domainsLimit: number
  }
  subaccounts?: {
    subaccountsCount: number
    subaccountsActiveCount: number
    subaccountsActiveLimit: number
  }
}

export const useGetAccountLimits = () => {
  const { data, isLoading, error } = useSWRImmutable<
    AccountLimitsResponse,
    AxiosError
  >('account/limits')

  const landingPages = data?.landingPages
  const uniqueVisits = data?.uniqueVisits
  const uniqueUsers = data?.uniqueUsers
  const domains = data?.domains
  const subaccounts = data?.subaccounts

  const limitsArray = [
    {
      count: landingPages?.landingPagePublicationCount,
      limit: landingPages?.landingPagePublicationLimit
    },
    {
      count: uniqueVisits?.uniqueVisitsCount,
      limit: uniqueVisits?.uniqueVisitsLimit
    },
    { count: domains?.activeOwnDomainsCount, limit: domains?.domainsLimit },
    {
      count: subaccounts?.subaccountsActiveCount,
      limit: subaccounts?.subaccountsActiveLimit
    }
  ].map(({ count, limit }) => {
    if (count === undefined || limit === undefined || limit === -1) {
      return null
    }

    if (count === limit) {
      return 'warning'
    }

    if (count > limit) {
      return 'alert'
    }

    return null
  })

  const isWarning = limitsArray.some(limit => limit === 'warning')
  const isAlert = limitsArray.some(limit => limit === 'alert')

  return {
    landingPages,
    uniqueVisits,
    uniqueUsers,
    domains,
    subaccounts,
    isWarning,
    isAlert,
    data,
    isLoading,
    error
  }
}
