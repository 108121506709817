import { z } from 'zod'
import { toFormikValidationSchema } from 'zod-formik-adapter'

const schema = toFormikValidationSchema(
  z.object({
    smartSectionName: z.string(),
    width: z.union([z.literal('960'), z.literal('1200')])
  })
)

export default schema
