import { Loader } from '@ui-kit/Loader'
import { Fragment } from 'react'

import { Heading } from './components/Heading'
import { Row } from './components/Row'
import { ItemBase, TableProps } from './types'

export const Table = <Item extends ItemBase>({
  columns,
  data,
  rowActions,
  emptyMessage,
  hasFullBorder = false,
  isLoading
}: TableProps<Item>) => {
  return (
    <Fragment>
      <Heading columns={columns} hasFullBorder={hasFullBorder} />

      {isLoading && <Loader data-testid='table-loader' />}

      {data?.length === 0 && !isLoading && emptyMessage}

      {data?.map((row: Item, index) => (
        <Row
          id={row?.id || `table-row-${index}`}
          key={row.identifier}
          columns={columns}
          data={row}
          rowActions={rowActions}
          hasFullBorder={hasFullBorder}
        />
      ))}
    </Fragment>
  )
}
