import { filters } from '@components/ProductUpdates/constants'
import { formatDateUTCToDisplay } from '@helpers/date'
import { useStyles } from '@hooks/useStyles'
import {
  Badge,
  Button,
  Divider,
  Heading,
  Html,
  Image,
  Paragraph,
  Spacer
} from '@landingi/landingi-ui-kit'
import { FC, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import styles from './Notification.module.scss'

interface NotificationProps {
  category: keyof typeof filters
  published_at: string
  content: {
    title: string
    description: string
    link: string
    img_url: string
    button_label: string
  }
  isNewNotification: boolean
}

export const Notification: FC<NotificationProps> = ({
  category,
  content,
  published_at,
  isNewNotification
}) => {
  const { t } = useTranslation()

  const { title, description, img_url, link, button_label } = content

  const wrapperStyles = useStyles({
    [styles.wrapper]: true,
    [styles.wrapper__link]: link,
    [styles['wrapper__new-notification']]: isNewNotification
  })

  const handleNotificationOpen = () => {
    window.open(link, '_blank')
  }

  const notificationAction = link ? { onClick: handleNotificationOpen } : {}

  return (
    <div className={wrapperStyles} {...notificationAction}>
      <Row justifyContent='space-between' alignItems='center'>
        <Badge type={filters[category]}>{category}</Badge>

        <Paragraph size={12} color='accent-2' padding='none'>
          {formatDateUTCToDisplay(published_at, { withTime: false })}
        </Paragraph>
      </Row>

      <Spacer space='tiny' />

      {img_url ? <Image src={img_url} auto /> : null}

      <Spacer space='tiny' />

      <Heading level={4} margin='none'>
        {title}
      </Heading>

      <Spacer space='tiny' />

      <Paragraph size={14} padding='none'>
        <Html value={description} />
      </Paragraph>

      {link ? (
        <Fragment>
          <Spacer space='tiny' />

          <Spacer space='mini' />

          <Row justifyContent='flex-end'>
            <Button size='tiny' variant='secondary'>
              {button_label || t('word.learn.more')}
            </Button>
          </Row>
        </Fragment>
      ) : null}

      <Spacer />

      <Divider />
    </div>
  )
}

Notification.displayName = 'ProductUpdatesNotification'
