import { Tour } from '@components/Tour'
import { useFeatureFlags } from '@contexts/featureFlags'
import { waitForElementVisibleInDOM } from '@helpers/dom'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { addTourDisplayID } from '@pages/Landings/routes/LandingsPublishingOptions/helpers/addTourDisplayID'
import { paragraphColor } from '@pages/Landings/routes/LandingsPublishingOptions/helpers/paragraphColor'
import { steps } from '@pages/Landings/routes/LandingsPublishingOptions/tours/changeMainUrlSteps'
import { steps as stepsForFreePlans } from '@pages/Landings/routes/LandingsPublishingOptions/tours/changeMainUrlStepsForFreePlans'
import { Panel, Paragraph, Spacer, useTable } from '@ui-kit'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'

import { columns } from './columns'
import { ExtraHeaderContent } from './components/ExtraHeaderContent'
import { RowActions } from './components/RowActions'
import styles from './Table.module.scss'

export const Table = () => {
  const { t } = useTranslation()

  const [isTourVisible, setTourVisible] = useState(false)

  const {
    landingPageUrls: {
      data: { collection },
      isLoading
    },
    publishingOptions: {
      data: { isLandingPagePublished },
      isLoading: isPublishingOptionsLoading
    }
  } = usePublishingOptionsContext()

  const hasAllowTestAutoDomainFeatureFlag = useFeatureFlags(
    'ALLOW_TEST_AUTODOMAIN'
  )

  const freePlanRestrictionsFeatureFlag = useFeatureFlags(
    'FREE_PLAN_RESTRICTIONS'
  )

  const isFreePlanRestrictions =
    freePlanRestrictionsFeatureFlag && !hasAllowTestAutoDomainFeatureFlag

  const isEmptyList = collection.length === 0

  const { Table } = useTable({
    columns,
    data: addTourDisplayID(collection),
    isLoading: isLoading || isPublishingOptionsLoading,
    rowActions: RowActions
  })

  const handleCheckVisibilityTourElement = async () => {
    const isVisible = await waitForElementVisibleInDOM(
      '#publishing-options-change-main-url-tour'
    )

    setTourVisible(isVisible)
  }

  useEffect(() => {
    handleCheckVisibilityTourElement()
  }, [])

  return (
    <Fragment>
      {isTourVisible && (
        <Tour
          targetName='publishing-options-modal-tour'
          steps={freePlanRestrictionsFeatureFlag ? stepsForFreePlans : steps}
        />
      )}

      <Row
        className={styles['table-description']}
        horizontal='space-between'
        vertical='center'
      >
        <Paragraph
          size={18}
          color={paragraphColor(isLandingPagePublished)}
          weight={400}
        >
          {t('publishing.options.title.table')}
        </Paragraph>

        <ExtraHeaderContent />
      </Row>

      <Spacer space={30} />

      {isFreePlanRestrictions && isEmptyList ? null : (
        <Panel border='neutral-2' surface={0} padding={0}>
          <Table />
        </Panel>
      )}

      <Spacer space={30} />
    </Fragment>
  )
}

Table.displayName = 'PublishingOptionsViewsMainTable'
