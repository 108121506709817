import { ANNUALLY, BillingPeriodType, MONTHLY } from '@constants/billingPeriods'
import { CurrencyType, PLN, USD } from '@constants/currencies'
import {
  AGENCY_PLANS,
  defaultAgencyPlan,
  defaultProfessionalPlan,
  PROFESSIONAL_PLANS
} from '@constants/plans'
import { PROMOTION_3M_PLANS, PROMOTION_12M_PLANS } from '@constants/promotions'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import {
  convertPriceToNetto,
  formatPrice,
  getActiveDiscountForPlanAndPeriod,
  getPackageName,
  getPackagePrice
} from '@helpers/payment'
import { useCalcDatesForPromotion } from '@hooks/useCalcDatesForPromotion'
import { PROMOTION_3M_END_DATE, PROMOTION_3M_START_DAY } from '@hooks/useSale'
import { useGetAccountInfo } from '@services/account'
import { Discount } from '@services/discounts'
import { useGetAccountLimits } from '@services/limits'
import { getDateRangeForEveryMonth, useCountdown } from '@ui-kit'
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from 'react'
import useSWRImmutable from 'swr/immutable'

export interface Option {
  name: string
  key: string
  value: string
}

export interface Limit {
  name: string
  key: string
  value: string
}

export interface Pricing {
  currency: string
  price: number
}

export interface Tariff {
  period: number
  pricing: Pricing[]
}

export interface PackageType {
  id: number
  identifier: string
  name: string
  record_key: string
  features: string[]
  options: Option[]
  limits: Limit[]
  tariffs: Tariff[]
}

const useAccountPackageState = () => {
  const value = useSWRImmutable('packages')

  return value
}

const AccountPackageContext = createContext<{
  getAnnualSavingForPlan: (planRecordKey: string) => string
  accountCurrency: CurrencyType
  chosenBillingPeriod: BillingPeriodType
  setChosenBillingPeriod: Dispatch<SetStateAction<BillingPeriodType>>
  chosenProfessionalPlanRecordKey: string
  setChosenProfessionalPlanRecordKey: Dispatch<SetStateAction<string>>
  chosenAgencyPlanRecordKey: string
  setChosenAgencyPlanRecordKey: Dispatch<SetStateAction<string>>
  planRecordKey?: string
  getActiveDiscountForPlan: (
    planRecordKey: string
  ) => Discount | undefined | null
  isCurrentPlan: (planRecordKey: string) => boolean
  getPackageNameForPlan: (planRecordKey: string) => string
  getPackagePriceForPlan: (planRecordKey: string) => number
  getPackagePriceForPlanInMonths: (planRecordKepy: string) => number
  hasUserExceededLimitForPlan: (planRecordKey: string) => boolean
  isPricingDataLoading: boolean
  hasAccessToPromotion3M: (planRecordKey: string) => boolean | undefined
  hasAccessToPromotion12M: (planRecordKey: string) => boolean | undefined
  hasAccessToPromotionForFreePlans: boolean
} | null>(null)

type ProviderProps = {
  children: ReactNode
}

export const AccountPackageProvider: FC<ProviderProps> = ({ children }) => {
  const value = useAccountPackageState()

  const {
    accountCurrency,
    billingPeriod,
    planRecordKey: currentPlanRecordKey,
    discounts,
    user,
    hasSubscription
  } = useUserContext()

  const { hasCustomOffer } = useGetAccountInfo()

  const { uniqueVisits: uniqueVisitsLimit, domains: domainsLimits } =
    useGetAccountLimits()

  const {
    data: packages,
    isLoading: isPricingDataLoading
  }: { data: PackageType[]; isLoading: boolean } = value

  const hasAccessToPromotion3MFF = useFeatureFlags('PROMOTION_3M')

  const hasAccessToPromotion12MFF = useFeatureFlags('PROMOTION_12M')

  const hasAccessToPromotion12M = hasAccessToPromotion12MFF && !hasCustomOffer

  const hasFreePlanRestrictions = useFeatureFlags('FREE_PLAN_RESTRICTIONS')

  const hasAccessToPromotionForFreePlan = useFeatureFlags(
    'PROMOTION_FOR_FREE_PLAN'
  )

  const hasAccessToPricing2022 = useFeatureFlags('PRICING_2022')

  const defaultChosenProfessionalPlan = PROFESSIONAL_PLANS.find(
    plan => plan === currentPlanRecordKey
  )

  const defaultBillingPeriod = () => {
    if (!hasAccessToPricing2022) {
      if (hasAccessToPromotion12M) {
        if (hasSubscription && billingPeriod) {
          return billingPeriod === ANNUALLY ? ANNUALLY : MONTHLY
        }

        return ANNUALLY
      }

      return MONTHLY
    }

    if (hasAccessToPromotion12M) {
      if (hasSubscription && billingPeriod) {
        return billingPeriod
      }

      return ANNUALLY
    }

    return billingPeriod || MONTHLY
  }

  const [chosenBillingPeriod, setChosenBillingPeriod] =
    useState(defaultBillingPeriod)

  const defaultChosenAgencyPlan = AGENCY_PLANS.find(
    plan => plan === currentPlanRecordKey
  )

  const [chosenProfessionalPlanRecordKey, setChosenProfessionalPlanRecordKey] =
    useState<string>(defaultChosenProfessionalPlan || defaultProfessionalPlan)
  const [chosenAgencyPlanRecordKey, setChosenAgencyPlanRecordKey] =
    useState<string>(defaultChosenAgencyPlan || defaultAgencyPlan)

  const isInSQLStage = user.platform_stage === 'SQL'

  const { date, promotionIsVisible } = useCalcDatesForPromotion()

  const { isFinished: promotionForFreePlanIsFinished } = useCountdown(date)

  const hasAccessToPlatformDevelopment = useFeatureFlags('PLATFORM_DEVELOPMENT')

  const [startDate, endDate] = getDateRangeForEveryMonth({
    startDay: PROMOTION_3M_START_DAY
  })

  const { isFinished } = useCountdown(
    hasAccessToPlatformDevelopment
      ? { startDate, endDate }
      : { endDate: PROMOTION_3M_END_DATE }
  )

  const hasAccessToPromotion3M = (planRecordKey: string) =>
    hasAccessToPromotion3MFF &&
    PROMOTION_3M_PLANS.includes(
      planRecordKey as (typeof PROMOTION_3M_PLANS)[number]
    ) &&
    chosenBillingPeriod === MONTHLY &&
    !isFinished

  const hasAccessToPromotion12MAndCorrectPlans = (planRecordKey: string) =>
    hasAccessToPromotion12M &&
    PROMOTION_12M_PLANS.includes(
      planRecordKey as (typeof PROMOTION_12M_PLANS)[number]
    ) &&
    chosenBillingPeriod === ANNUALLY

  const hasAccessToPromotionForFreePlans =
    !!hasFreePlanRestrictions &&
    !!hasAccessToPromotionForFreePlan &&
    isInSQLStage &&
    chosenBillingPeriod === MONTHLY &&
    !promotionForFreePlanIsFinished &&
    promotionIsVisible

  const isCurrentPlan = (planRecordKey?: string) =>
    planRecordKey === currentPlanRecordKey &&
    billingPeriod === chosenBillingPeriod

  const getActiveDiscountForPlan = (planRecordKey: string) =>
    getActiveDiscountForPlanAndPeriod(
      discounts,
      planRecordKey,
      chosenBillingPeriod
    )

  const getMonthlyPlanPrice = (
    planRecordKey: string,
    shouldConvertToNettoWhenPLN: boolean
  ) => {
    const price = getPackagePrice({
      packages,
      periodToFind: MONTHLY,
      currencyToFind: accountCurrency,
      packageRecordKey: planRecordKey
    })

    if (shouldConvertToNettoWhenPLN && accountCurrency === PLN) {
      return convertPriceToNetto(price)
    }

    return price
  }

  const getAnnualPlanPrice = (
    planRecordKey: string,
    shouldConvertToNettoWhenPLN: boolean
  ) => {
    const price = getPackagePrice({
      packages,
      periodToFind: ANNUALLY,
      currencyToFind: accountCurrency,
      packageRecordKey: planRecordKey
    })

    if (shouldConvertToNettoWhenPLN && accountCurrency === PLN) {
      return convertPriceToNetto(price)
    }

    return price
  }

  const getAnnualSavingForPlan = (planRecordKey: string) =>
    formatPrice(
      getMonthlyPlanPrice(planRecordKey, true) * 12 -
        getAnnualPlanPrice(planRecordKey, true)
    )

  const getPackagePriceForPlan = (planRecordKey: string) =>
    getPackagePrice({
      packages,
      periodToFind: chosenBillingPeriod,
      currencyToFind: accountCurrency,
      packageRecordKey: planRecordKey
    })

  const getPackagePriceForPlanInMonths = (planRecordKey: string) =>
    Number(
      (getPackagePriceForPlan(planRecordKey) / chosenBillingPeriod).toFixed(2)
    )

  const getPackageNameForPlan = (packageRecordKey: string) =>
    getPackageName(packages, packageRecordKey)

  const getPackageLimits = (planRecordKey: string) => {
    const limits = packages.find(
      plan => plan.record_key === planRecordKey
    )?.limits

    const number_of_domains = Number(
      limits?.find(limit => limit.name === 'number_of_domains')?.value
    )

    const unique_visits = Number(
      limits?.find(limit => limit.name === 'unique_visits')?.value
    )

    const fortmattedLimits = {
      number_of_domains:
        number_of_domains === -1 ? Infinity : number_of_domains,
      unique_visits: unique_visits === -1 ? Infinity : unique_visits
    }

    return fortmattedLimits
  }

  const hasUserExceededLimitForPlan = (planRecordKey: string) => {
    const limits = getPackageLimits(planRecordKey)

    const uniqueVisitsCount = uniqueVisitsLimit?.uniqueVisitsCount || 0
    const domainsCount = domainsLimits?.activeOwnDomainsCount || 0

    const hasUserExceededVisitsLimits = uniqueVisitsCount > limits.unique_visits
    const hasUserExceededDomainsLimits = domainsCount > limits.number_of_domains

    return hasUserExceededVisitsLimits || hasUserExceededDomainsLimits
  }

  return (
    <AccountPackageContext.Provider
      value={{
        chosenProfessionalPlanRecordKey,
        setChosenProfessionalPlanRecordKey,
        chosenAgencyPlanRecordKey,
        setChosenAgencyPlanRecordKey,
        getAnnualSavingForPlan,
        accountCurrency: (accountCurrency || USD) as CurrencyType,
        chosenBillingPeriod,
        setChosenBillingPeriod,
        planRecordKey: currentPlanRecordKey,
        getActiveDiscountForPlan,
        isCurrentPlan,
        getPackageNameForPlan,
        getPackagePriceForPlan,
        getPackagePriceForPlanInMonths,
        hasUserExceededLimitForPlan,
        isPricingDataLoading,
        hasAccessToPromotion3M,
        hasAccessToPromotion12M: hasAccessToPromotion12MAndCorrectPlans,
        hasAccessToPromotionForFreePlans
      }}
    >
      {children}
    </AccountPackageContext.Provider>
  )
}

export const useAccountPackageContext = () => {
  const accountPackage = useContext(AccountPackageContext)

  if (!accountPackage) {
    throw new Error(
      'useAccountPackageContext must be used inside AccountPackageProvider'
    )
  }

  return accountPackage
}
