import { Heading, HeadingLevel } from '@ui-kit/Heading'
import { Image } from '@ui-kit/Image'
import { Paragraph, ParagraphSize } from '@ui-kit/Paragraph'
import { Spacer, SpacerSize } from '@ui-kit/Spacer'
import { FC, Fragment, ReactElement, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'simple-flexbox'

import { elementSizes, messageTypes } from './constants'

export type MessageTypes = 'no-data' | 'not-found'
export type MessageSizes = 'small' | 'medium' | 'large'

export interface MessageProps {
  children?: ReactNode
  type?: MessageTypes
  image?: string
  i18n?: {
    title?: string
    message?: string | ReactElement
  }
  size?: MessageSizes
  sizes?: {
    imageHeight?: number
    titleLevel?: HeadingLevel
    messageSize?: ParagraphSize
    spaceSize?: SpacerSize
  }
  weights?: {
    titleWeight?: 400 | 600
    messageWeight?: 400 | 600
  }
}

export const Message: FC<MessageProps> = ({
  children,
  type,
  image,
  i18n = {},
  size,
  sizes,
  weights
}) => {
  const { t } = useTranslation()

  const imageUrl = image ?? (type && messageTypes[type].image)
  const title = i18n.title ?? (type && messageTypes[type].title)
  const message = i18n.message ?? (type && messageTypes[type].message)
  const selectedSize = size ?? (type && messageTypes[type].size) ?? 'large'

  const imageHeight =
    sizes?.imageHeight ?? elementSizes[selectedSize].imageHeight
  const titleLevel = sizes?.titleLevel ?? elementSizes[selectedSize].titleLevel
  const messageSize =
    sizes?.messageSize ?? elementSizes[selectedSize].messageSize
  const spaceSize = sizes?.spaceSize ?? elementSizes[selectedSize].spaceSize

  const titleWeight = weights?.titleWeight ?? 400
  const messageWeight = weights?.messageWeight ?? 400

  return (
    <Column vertical='center' horizontal='center'>
      {imageUrl && <Image src={imageUrl} height={`${imageHeight}px`} />}

      {title && (
        <Heading level={titleLevel} align='center' weight={titleWeight}>
          {t(title)}
        </Heading>
      )}

      {message && (
        <Fragment>
          <Spacer space={spaceSize} />

          <Paragraph
            size={messageSize}
            color='neutral-6'
            align='center'
            weight={messageWeight}
          >
            {typeof message === 'string' ? t(message) : message}
          </Paragraph>
        </Fragment>
      )}

      {children}
    </Column>
  )
}

Message.displayName = 'Message'
