import { Paragraph, Spreader, StepNumber } from '@ui-kit'
import { TFunction } from 'i18next'
import { Trans } from 'react-i18next'
import { Row } from 'simple-flexbox'

export const getFAQ = (t: TFunction<'translation', undefined>) => [
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={1} />

        <Spreader spread={15} />

        <Trans i18nKey='addons.store.ip.domain.whitelisting.faq.question.1' />
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        <Trans i18nKey='addons.store.ip.domain.whitelisting.faq.answer.1' />
      </Paragraph>
    )
  },
  {
    title: (
      <Row alignItems='center'>
        <StepNumber step={2} />

        <Spreader spread={15} />

        <Trans i18nKey='addons.store.ip.domain.whitelisting.faq.question.2' />
      </Row>
    ),
    content: (
      <Paragraph size={14}>
        <Trans
          i18nKey='addons.store.ip.domain.whitelisting.faq.answer.2'
          components={{
            a: (
              <a
                href={t(
                  'addons.store.ip.domain.whitelisting.faq.answer.2.learn.more.link'
                )}
                target='_blank'
                rel='noreferrer'
              />
            )
          }}
        />
      </Paragraph>
    )
  }
]
