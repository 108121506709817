import { Badge } from '@ui-kit/Badge'
import { Icon } from '@ui-kit/Icon'
import { Spreader } from '@ui-kit/Spreader'
import { useTranslation } from 'react-i18next'

import styles from './badges.module.scss'

export const MostPopularBadge = () => {
  const { t } = useTranslation()

  return (
    <Badge variant='warning-filled'>
      <Icon icon='icon-star' color='white' />
      <Spreader spread={5} />
      {t('addons.store.badge.most.popular')}
    </Badge>
  )
}

export const NewBadgeNoIcon = () => {
  const { t } = useTranslation()

  return <Badge variant='info-filled'>{t('addons.store.badge.new')}</Badge>
}

export const NewBadge = () => {
  const { t } = useTranslation()

  return (
    <Badge variant='info-filled'>
      <Icon icon='icon-ai-option' color='white' />
      <Spreader spread={5} />
      {t('addons.store.badge.new')}
    </Badge>
  )
}

export const FeatureBadge = () => {
  const { t } = useTranslation()

  return (
    <Badge variant='neutral4-border'>{t('addons.store.badge.feature')}</Badge>
  )
}

export const ServiceBadge = () => {
  const { t } = useTranslation()

  return (
    <Badge variant='neutral4-border'>{t('addons.store.badge.service')}</Badge>
  )
}

export const SevenDaysFreeTrialBadge = () => {
  const { t } = useTranslation()

  return (
    <Badge variant='neutral4-border'>
      {t('addons.store.badge.14.days.free.trial')}
    </Badge>
  )
}

export const IncludedInPlanBadge = () => {
  const { t } = useTranslation()

  return (
    <Badge variant='success-filled'>
      {t('addons.store.badge.included.in.plan')}
    </Badge>
  )
}
export const ActiveBadge = () => {
  const { t } = useTranslation()

  return (
    <Badge variant='success-filled'> {t('addons.store.badge.active')}</Badge>
  )
}

export const OneOffPaymentBadge = () => {
  const { t } = useTranslation()

  return (
    <Badge variant='neutral4-border'>
      {t('addons.store.badge.one.off.payment')}
    </Badge>
  )
}

export const EditorBadge = () => {
  const { t } = useTranslation()

  return (
    <Badge variant='neutral4-border'>{t('addons.store.badge.editor')}</Badge>
  )
}

export const MOST_POPULAR = 'MOST_POPULAR'
export const NEW = 'NEW'
export const FEATURE = 'FEATURE'
export const SERVICE = 'SERVICE'
export const SEVEN_DAYS_FREE_TRIAL = 'SEVEN_DAYS_FREE_TRIAL'
export const INCLUDED_IN_PLAN = 'INCLUDED_IN_PLAN'
export const ACTIVE = 'ACTIVE'
export const ONE_OFF_PAYMENT = 'ONE_OFF_PAYMENT'
export const EDITOR = 'EDITOR'
export const NEW_NO_ICON = 'NEW_NO_ICON'

export const badges = {
  MOST_POPULAR: MostPopularBadge,
  NEW: NewBadge,
  FEATURE: FeatureBadge,
  SERVICE: ServiceBadge,
  SEVEN_DAYS_FREE_TRIAL: SevenDaysFreeTrialBadge,
  INCLUDED_IN_PLAN: IncludedInPlanBadge,
  ACTIVE: ActiveBadge,
  ONE_OFF_PAYMENT: OneOffPaymentBadge,
  EDITOR: EditorBadge,
  NEW_NO_ICON: NewBadgeNoIcon
} as const

/**
 * if user is on plan that has access to addon by default, we should
 * show INCLUDED IN PLAN badge. If user doesn't have access to addon by default
 * but has feature flag for this addon on their account we should show ACTIVE badge.
 * If addon doesn't have limitedInPlans field it means it isn't available
 * on any plan by default and user always has to buy it - so we always show ACTIVE badge.
 */
const getActiveBadge = (currentPlan?: string, limitedInPlans?: string[]) => {
  if (!currentPlan) {
    return null
  }

  if (!limitedInPlans || limitedInPlans.includes(currentPlan)) {
    return <ActiveBadge />
  }

  return <IncludedInPlanBadge />
}

export const renderBadges = ({
  badgesToRender,
  currentPlan,
  limitedInPlans,
  isAddonActive,
  hasUsedTrial,
  isLoadingUsedProductAddOnUuids
}: {
  badgesToRender: (keyof typeof badges)[]
  currentPlan?: string
  limitedInPlans?: string[]
  isAddonActive?: boolean
  hasUsedTrial: boolean
  isLoadingUsedProductAddOnUuids?: boolean
}) => {
  const activeBadge = getActiveBadge(currentPlan, limitedInPlans)

  return (
    <div className={styles.badges}>
      {badgesToRender.map(badge => {
        const Badge = badges[badge]

        if (
          (isLoadingUsedProductAddOnUuids || isAddonActive || hasUsedTrial) &&
          badge === SEVEN_DAYS_FREE_TRIAL
        ) {
          return null
        }

        return <Badge key={badge} />
      })}

      {isAddonActive && activeBadge}
    </div>
  )
}
