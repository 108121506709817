import { TYPES } from '@pages/Landings/routes/LandingsPublishingOptions/constants'
import { UrlType } from '@services/landings/publishingOptions'

const { WORDPRESS, EMBED, TEST } = TYPES

type DataType = UrlType & { id?: string }

export const addTourDisplayID = (data: DataType[]) => {
  const isWordpressOrEmbed = data.find(
    ({ publicationType }) =>
      publicationType === WORDPRESS || publicationType === EMBED
  )

  const hasMainDomainSetAsTest = data.some(
    ({ isMain, publicationType }) => publicationType === TEST && isMain
  )

  if (
    isWordpressOrEmbed &&
    hasMainDomainSetAsTest &&
    !isWordpressOrEmbed?.isFake
  ) {
    isWordpressOrEmbed.id = 'publishing-options-change-main-url-tour'
  }

  return data
}
