import { Paragraph } from '@ui-kit/Paragraph'
import { Spacer } from '@ui-kit/Spacer'
import { Trans } from 'react-i18next'
import { Column } from 'simple-flexbox'

export const steps = [
  {
    target: '#publishing-options-change-main-url-tour',
    content: (
      <Column>
        <Paragraph color='white' size={14} weight={600}>
          <Trans i18nKey='publishing.options.action.dropdown.change.main.url' />
        </Paragraph>

        <Spacer space={10} />

        <Paragraph color='white' size={12}>
          <Trans i18nKey='publishing.options.action.dropdown.change.main.url.description.for.free' />
        </Paragraph>
      </Column>
    ),
    offset: 10,
    disableBeacon: true,
    placement: 'bottom-end' as const,
    locale: {
      last: (
        <Trans i18nKey='publishing.options.action.dropdown.change.main.url.ok.close.tour' />
      )
    }
  }
]
