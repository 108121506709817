/* eslint-disable simple-import-sort/imports */
// sorting imports is disabled because ReactToastify.css must go before other CSS files
// so its styles can be overriden
import '@landingi/landingi-ui-kit/dist/app-uikit.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import './main.scss'

import { ErrorBoundaryFallback } from '@components/ErrorBoundaryFallback'
import client from '@lib/http/client'
import { Root } from '@pages/Root'
import * as Sentry from '@sentry/react'
import { BrowserRouter } from 'react-router-dom'
import { SWRConfig } from 'swr'

const fetcher = (url: string) => client.get(url).then(res => res.data)

const App = () => (
  <Sentry.ErrorBoundary fallback={<ErrorBoundaryFallback />}>
    <BrowserRouter>
      <SWRConfig
        value={{
          fetcher,
          revalidateIfStale: true,
          revalidateOnFocus: false,
          revalidateOnReconnect: true,
          revalidateOnMount: true
        }}
      >
        <Root />
      </SWRConfig>
    </BrowserRouter>
  </Sentry.ErrorBoundary>
)

export default Sentry.withProfiler(App)
