import { MODAL_GENERATE_QR_CODE } from '@components/Modals/ModalGenerateQRCode'
import { CLICK_ON_QR_CODE_MODAL, PUBLISHING_OPTIONS } from '@constants/mixpanel'
import { useFeatureFlags } from '@contexts/featureFlags'
import NiceModal from '@ebay/nice-modal-react'
import { isTestDomain } from '@helpers/domains'
import { useCopyToClipboard } from '@hooks/useCopyToClipboard'
import { useLazyService } from '@hooks/useLazyService'
import { MODAL_REMOVE_URL } from '@pages/Landings/routes/LandingsPublishingOptions/components/Modals/ModalRemoveUrl'
import { TYPES } from '@pages/Landings/routes/LandingsPublishingOptions/constants'
import { usePublishingOptionsContext } from '@pages/Landings/routes/LandingsPublishingOptions/context'
import { PublicationType } from '@services/landings/list/types'
import {
  assignDomainToLandingPage,
  setAsMainOption
} from '@services/landings/publishingOptions'
import { mixpanelEvent } from '@services/mixpanel'
import {
  Button,
  Dropdown,
  DropdownElement,
  Icon,
  Spreader,
  Tooltip
} from '@ui-kit'
import { FC, Fragment, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'simple-flexbox'
import { v4 as uuidv4 } from 'uuid'

interface RowActionsProps {
  publicationType: PublicationType
  isMain: boolean
  url: string
  domain: string
  path: string
  identifier: string
  isFake: boolean
}

export const RowActions: FC<RowActionsProps> = ({
  publicationType,
  isMain,
  url,
  domain,
  path,
  identifier,
  isFake
}) => {
  const { t } = useTranslation()

  const [handleCopy] = useCopyToClipboard(url)

  const {
    publishingOptions: {
      data: { isLandingPagePublished, accountTestDomain, isDedicatedTestDomain }
    },
    uuid,
    handleChangeUrl,
    setPublicationType,
    landingPageUrls: { handleRefreshlUrls }
  } = usePublishingOptionsContext()

  const isSemrushIntegration = useFeatureFlags('SEMRUSH_INTEGRATION')

  const hasAllowTestAutoDomainFeatureFlag = useFeatureFlags(
    'ALLOW_TEST_AUTODOMAIN'
  )

  const freePlanRestrictionsFeatureFlag = useFeatureFlags(
    'FREE_PLAN_RESTRICTIONS'
  )

  const isFreePlanRestrictions =
    freePlanRestrictionsFeatureFlag && !hasAllowTestAutoDomainFeatureFlag

  const hasNotAccessToQRCode = isTestDomain(domain) && isFreePlanRestrictions

  const isCustomDomain = publicationType === TYPES.CUSTOM
  const isWordpress = publicationType === TYPES.WORDPRESS
  const isEmbed = publicationType === TYPES.EMBED

  const isRestrictionForFreeAccount =
    isFreePlanRestrictions && isCustomDomain && isMain

  const renderDropdown = !(
    (!isCustomDomain && isMain) ||
    isRestrictionForFreeAccount
  )

  const renderSetAsMain = !isMain

  const renderRemoveButton = (isWordpress || isEmbed) && !isMain

  const [assignDomainToLandingPageRequest] = useLazyService(
    assignDomainToLandingPage,
    {
      onSuccess: () => {
        handleRefreshlUrls()

        setPublicationType({ publicationType: TYPES.TEST })
      },
      successToastText: t('publishing.options.change.url.toast.success'),
      errorToastText: t('toast.error.something.went.wrong')
    }
  )

  const customDropdownTrigger = ({ isDisabled }: { isDisabled: boolean }) => (
    <Button
      variant='text-neutral'
      icon='icon-ellipsis-v'
      disabled={isDisabled}
      isSquare
    />
  )

  const handleSwitchToTestDomain = () =>
    assignDomainToLandingPageRequest({
      uuid,
      domain: accountTestDomain,
      path: `/${uuidv4()}`
    })

  const [setAsMainOptionRequest] = useLazyService(setAsMainOption, {
    onSuccess: () => {
      handleRefreshlUrls()

      setPublicationType({ publicationType })
    },
    successToastText: t('publishing.options.actions.setmain.toast.success'),
    errorToastText: t('toast.error.something.went.wrong')
  })

  const handleSetAsMain = () =>
    setAsMainOptionRequest({ uuid, type: publicationType, url })

  const handleRemoveUrl = () =>
    NiceModal.show(MODAL_REMOVE_URL, {
      uuid,
      url_uuid: identifier
    })

  const handleOpenQRCodeModal = () => {
    mixpanelEvent({
      name: CLICK_ON_QR_CODE_MODAL,
      properties: {
        click_location: PUBLISHING_OPTIONS
      }
    })

    NiceModal.show(MODAL_GENERATE_QR_CODE, {
      url,
      clickLocation: PUBLISHING_OPTIONS
    })
  }

  const handleOpen = useCallback(() => window.open(url, '_blank'), [url])

  if (isFake) return null

  return (
    <Row>
      <Tooltip content={t('copy.link.to.clipboard')} placement='top'>
        <Button
          variant='text-neutral'
          size={14}
          onClick={handleCopy}
          isSquare
          disabled={!isLandingPagePublished}
        >
          <Icon icon='icon-link' />
        </Button>
      </Tooltip>

      {!isSemrushIntegration && (
        <Tooltip
          content={
            hasNotAccessToQRCode
              ? t('modal.generate.qr.code.tooltip.disabled')
              : t('modal.generate.qr.code.tooltip')
          }
        >
          <Button
            variant='text-neutral'
            size={14}
            onClick={handleOpenQRCodeModal}
            isSquare
            disabled={!isLandingPagePublished || hasNotAccessToQRCode}
          >
            <Icon icon='icon-qr-code' />
          </Button>
        </Tooltip>
      )}

      <Tooltip
        content={t('publishing.options.actions.new.tab')}
        placement='top'
      >
        <Button
          variant='text-neutral'
          size={14}
          onClick={handleOpen}
          isSquare
          disabled={!isLandingPagePublished}
        >
          <Icon icon='icon-external-link-alt' />
        </Button>
      </Tooltip>

      {(isCustomDomain || isDedicatedTestDomain) && (
        <Fragment>
          <Spreader spread={10} />

          <Button
            variant='text-neutral'
            disabled={!isLandingPagePublished}
            onClick={() => handleChangeUrl(domain, path)}
          >
            {t('publishing.options.change.url')}
          </Button>
        </Fragment>
      )}

      {renderDropdown && (
        <Dropdown trigger={customDropdownTrigger}>
          {({ close }) => (
            <Fragment>
              {isCustomDomain && !isFreePlanRestrictions && (
                <DropdownElement
                  key={uuidv4()}
                  onClick={() => {
                    handleSwitchToTestDomain()

                    close()
                  }}
                  disabled={!isLandingPagePublished || !isCustomDomain}
                >
                  <Row alignItems='baseline'>
                    <Icon icon='icon-flask-fill' size={12} />

                    <Spreader spread={10} />

                    {t('publishing.options.actions.switch')}
                  </Row>
                </DropdownElement>
              )}

              {renderSetAsMain && (
                <DropdownElement
                  key={uuidv4()}
                  onClick={() => {
                    handleSetAsMain()

                    close()
                  }}
                  disabled={!isLandingPagePublished}
                  className='publishing-options-change-main-url-tour'
                >
                  <Row alignItems='baseline'>
                    <Icon icon='icon-star' size={12} />

                    <Spreader spread={10} />

                    {t('publishing.options.actions.setmain')}
                  </Row>
                </DropdownElement>
              )}

              {renderRemoveButton && (
                <DropdownElement
                  key={uuidv4()}
                  onClick={() => {
                    handleRemoveUrl()

                    close()
                  }}
                  disabled={!isLandingPagePublished}
                >
                  <Row alignItems='baseline'>
                    <Icon icon='icon-trash' size={12} />

                    <Spreader spread={10} />

                    {t('word.remove')}
                  </Row>
                </DropdownElement>
              )}
            </Fragment>
          )}
        </Dropdown>
      )}
    </Row>
  )
}

RowActions.displayName = 'PublishingOptionsViewsMainTableRowActions'
