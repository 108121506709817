import { AxiosError } from 'axios'
import useSWRImmutable from 'swr/immutable'

type Step = {
  name: string
}

export type AuthInfoResponse = {
  flow: {
    completed_step: Step
    current_step: Step
    steps: Step[]
  }
  free_trial_days: number
  package: { identifier: string }
}

export const useGetAuth = () => {
  const { data, error, isLoading, ...rest } = useSWRImmutable<
    AuthInfoResponse,
    AxiosError
  >('auth', {
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      if (error?.status === 401) return

      if (retryCount >= 3) return
    },
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false
  })

  const isAuth = Boolean(data && !error && !isLoading)

  return {
    data,
    error: error?.response?.data as {
      error: { message: string; code: string; status: number }
    },
    isAuth,
    isLoading,
    ...rest
  }
}
