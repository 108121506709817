import { useStyles } from '@hooks/useStyles'
import { getColumnWidth } from '@ui-kit/Table/helpers'
import { ItemBase, RowProps } from '@ui-kit/Table/types'

import styles from './Row.module.scss'

export const Row = <Item extends ItemBase>({
  id,
  columns,
  data,
  rowActions,
  hasFullBorder
}: RowProps<Item>) => {
  const rowStyles = useStyles({
    [styles.row]: true,
    [styles['row--full-border']]: hasFullBorder
  })

  return (
    <div
      id={id}
      data-testid='table-row'
      className={rowStyles}
      style={{
        gridTemplateColumns: columns
          .map(column => getColumnWidth(column.width, data))
          .join(' ')
      }}
    >
      {columns.map(column => (
        <div className={styles.cell} key={column.id}>
          {column.render ? column.render(data) : data[column.id]}
        </div>
      ))}

      {rowActions && rowActions(data) !== null && (
        <div className={styles.rowActions}>{rowActions(data)}</div>
      )}
    </div>
  )
}
