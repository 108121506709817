import { useFeatureFlags } from '@contexts/featureFlags'
import { FC, ReactElement, useEffect } from 'react'

interface RedirectProps {
  ff: string
  url: string
  children: ReactElement
}

export const Redirect: FC<RedirectProps> = ({ ff, url, children }) => {
  const hasAccess = useFeatureFlags(ff)

  useEffect(() => {
    if (!hasAccess) {
      window.open(url, '_self')
    }
  }, [hasAccess, url])

  return hasAccess ? children : null
}

Redirect.displayName = 'Redirect'
