import { useStyles } from '@hooks/useStyles'
import { getColumnWidth } from '@ui-kit/Table/helpers'
import { Column, ItemBase } from '@ui-kit/Table/types'

import styles from './Heading.module.scss'

export const Heading = <Item extends ItemBase>({
  columns,
  hasFullBorder
}: {
  columns: Column<Item>[]
  hasFullBorder?: boolean
}) => {
  const headingStyles = useStyles({
    [styles.heading]: true,
    [styles['heading--full-border']]: hasFullBorder
  })

  return (
    <div
      className={headingStyles}
      data-testid='table-heading'
      style={{
        gridTemplateColumns: columns
          .map(column => getColumnWidth(column.width))
          .join(' ')
      }}
    >
      {columns.map(column => (
        <div
          className={styles.cell}
          key={column.id}
          style={{
            width: getColumnWidth(column.width)
          }}
        >
          {column.name}
        </div>
      ))}
    </div>
  )
}
