import { Item } from '@components/Sidebar/components/Menu/Item'
import { ItemGroup } from '@components/Sidebar/components/Menu/ItemGroup'
import { ExpandType, MY_ACCOUNT } from '@components/Sidebar/types'
import { Account } from '@components/Topbar/components/Account'
import { Tour } from '@components/Tour'
import {
  AGENCY as AGENCY_ACCOUNT,
  CLIENT as CLIENT_ACCOUNT,
  NORMAL as NORMAL_ACCOUNT
} from '@constants/customerTypes'
import { NEW_LANDINGI_APP_URL } from '@constants/index'
import { UPGRADE_MODALS } from '@constants/modals'
import { useAcl } from '@contexts/acl'
import { useFeatureFlags } from '@contexts/featureFlags'
import { useUserContext } from '@contexts/user'
import { useUserCreatedBeforeDate } from '@hooks/useAddonsStoreTourVisible'
import { useAddonStoreHasBeenSeen } from '@hooks/useAddonStoreHasBeenSeen'
import { useBreakpoint } from '@hooks/useBreakpoint'
import { steps as addonsStoreSteps } from '@pages/AddonsStore/routes/Addons/tour/addonsStoreSteps'
import {
  AGENCY,
  DOMAINS,
  FONTS,
  LANDINGS,
  LEADS,
  LIGHTBOXES,
  ORDERS,
  POPUPS,
  PRODUCTS,
  PROGRAMMATIC,
  SMART_SECTIONS,
  TEMPLATES
} from '@routes/path'
import ADDONS_STORE from '@routes/path/addons'
import { Spacer } from '@ui-kit'
import { FC, Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { groupRoute, handleOpenTab } from './helpers'
import styles from './SidebarNavigation.module.scss'
import {
  conversionsActiveGroup,
  landingsActiveGroup,
  TABS,
  TabTypes
} from './types'

interface SidebarNavigationProps {
  isSidebarMini: boolean
}

export const SidebarNavigation: FC<SidebarNavigationProps> = ({
  isSidebarMini
}) => {
  const { t } = useTranslation()

  const location = useLocation()

  const isBiggerMobile = useBreakpoint('breakpoint-biggerMobile', 'max')
  const isDesktop = useBreakpoint('breakpoint-biggerLaptop', 'min')

  const [currentExpand, setCurrentExpand] = useState<ExpandType>(null)

  const {
    userName,
    userEmail,
    planRecordKey,
    leadsCount,
    isSupportMode,
    accountType,
    isTypeOwner,
    isTypeAdmin,
    isTypeDesigner
  } = useUserContext()

  const ECOMMERCE_MODULE_FF = useFeatureFlags('ECOMMERCE_MODULE')
  const SUBACCOUNT_RESOURCE_LIMITS_FF = useFeatureFlags(
    'SUBACCOUNT_RESOURCE_LIMITS'
  )
  const OWN_TEMPLATES_ADMIN_FF = useFeatureFlags('OWN_TEMPLATES_ADMIN')
  const UNBRANDED_CONTENT_FF = useFeatureFlags('UNBRANDED_CONTENT')
  const PRICING_2022_FF = useFeatureFlags('PRICING_2022')

  const hasAccessToSmartSections = useFeatureFlags('SMART_SECTIONS')
  const hasAccessToLightBoxes = useFeatureFlags('LIGHTBOXES')
  const hasAccesToPopups = useFeatureFlags('POPUPS')
  const hasAccessToAddonsMarketplace = useFeatureFlags('ADDONS_STORE')

  const hasSeenAddonStoreSinceLastUpdate = useAddonStoreHasBeenSeen()

  const hasAccessToSPAProductsPage = useFeatureFlags('SPA_PRODUCTS')
  const hasAccessToSPASmartSections = useFeatureFlags('SPA_SMART_SECTIONS')
  const hasAccessToSPAOrdersPage = useFeatureFlags('SPA_ORDERS')
  const hasAccessToSPALightboxesPage = useFeatureFlags('SPA_LIGHTBOXES')

  // TODO - change add-ons store release date
  const hasAccessToAddonsStoreTour =
    useUserCreatedBeforeDate('2024-03-12') && isDesktop

  const hasAccessToAddonsMarketplaceViewPage = useAcl(
    'payments.product_add_on.view_page'
  )
  const hasAccessToEcommerceOrders = useAcl('ecommerce.sidebar_orders')
  const hasAccessToEcommerceProducts = useAcl('ecommerce.sidebar_products')
  const hasAccessToFonts = useAcl('fonts.view_list')

  const hasAccessToSeeProgrammatic = !useFeatureFlags('SEMRUSH_INTEGRATION')
  const hasAccessToCredits = useFeatureFlags('CREDITS')
  const hasAccessToProgrammaticPage = useAcl(
    'programmatic_landing_page.process.view_list'
  )

  const hasAccessToSeeProgrammaticOnSidebar =
    hasAccessToSeeProgrammatic && hasAccessToCredits

  // Tabs: LANDINGS, SMART SECTIONS, LIGHTBOXES, POPUPS, DOMAINS and FONTS are always available
  const sidebarAccesses = {
    [TABS.PRODUCTS]: {
      [AGENCY_ACCOUNT]: hasAccessToEcommerceProducts,
      [CLIENT_ACCOUNT]: hasAccessToEcommerceProducts,
      [NORMAL_ACCOUNT]: hasAccessToEcommerceProducts
    },
    [TABS.LEADS]: {
      [AGENCY_ACCOUNT]: !isSupportMode,
      [CLIENT_ACCOUNT]: !isSupportMode,
      [NORMAL_ACCOUNT]: !isSupportMode && !isTypeDesigner
    },
    [TABS.ORDERS]: {
      [AGENCY_ACCOUNT]: hasAccessToEcommerceOrders,
      [CLIENT_ACCOUNT]: hasAccessToEcommerceOrders,
      [NORMAL_ACCOUNT]: hasAccessToEcommerceOrders
    },
    [TABS.PROGRAMMATIC]: {
      [AGENCY_ACCOUNT]: hasAccessToSeeProgrammaticOnSidebar,
      [CLIENT_ACCOUNT]: hasAccessToSeeProgrammaticOnSidebar,
      [NORMAL_ACCOUNT]: hasAccessToSeeProgrammaticOnSidebar
    },
    [TABS.LIMIT_PACKAGES]: {
      [AGENCY_ACCOUNT]: SUBACCOUNT_RESOURCE_LIMITS_FF,
      [CLIENT_ACCOUNT]: false,
      [NORMAL_ACCOUNT]: false
    },
    [TABS.TEMPLATES]: {
      [AGENCY_ACCOUNT]: OWN_TEMPLATES_ADMIN_FF,
      [CLIENT_ACCOUNT]: false,
      [NORMAL_ACCOUNT]: false
    },
    [TABS.INTEGRATIONS]: {
      [AGENCY_ACCOUNT]: !UNBRANDED_CONTENT_FF,
      [CLIENT_ACCOUNT]: !UNBRANDED_CONTENT_FF,
      [NORMAL_ACCOUNT]: !UNBRANDED_CONTENT_FF
    },
    [TABS.ADD_ONS]: {
      [AGENCY_ACCOUNT]: hasAccessToAddonsMarketplace || PRICING_2022_FF,
      [CLIENT_ACCOUNT]: false,
      [NORMAL_ACCOUNT]:
        hasAccessToAddonsMarketplace ||
        (PRICING_2022_FF && (isTypeOwner || isTypeAdmin))
    }
  }

  const handleOpenIntegrations = () =>
    window.open(t('sidebar.integrations.url'), '_blank')

  const handleOpenAddOns = () =>
    window.open(
      t('sidebar.add-ons.url', {
        email: userEmail,
        recordKey: planRecordKey
      }),
      '_blank'
    )

  const getAddonsItem = () =>
    hasAccessToAddonsMarketplace && hasAccessToAddonsMarketplaceViewPage ? (
      <Fragment>
        <Item
          identifier='sidebar-add-ons'
          to={ADDONS_STORE.ADDONS_STORE as ExpandType}
          text={t('sidebar.add-ons')}
          icon='icon-extension'
          sidebarMini={isSidebarMini}
          hasIndicator={!hasSeenAddonStoreSinceLastUpdate}
        />

        {hasAccessToAddonsStoreTour && (
          <Tour
            targetName='addons-store-navigation'
            steps={addonsStoreSteps}
            delay={1000}
          />
        )}
      </Fragment>
    ) : (
      <Item
        identifier='sidebar-add-ons'
        onClick={handleOpenAddOns}
        text={t('sidebar.add-ons')}
        icon='icon-extension'
        sidebarMini={isSidebarMini}
        hasExternalLink
      />
    )

  const hasAccessToTab = (tab: TabTypes) =>
    sidebarAccesses[tab][accountType || NORMAL_ACCOUNT]

  return (
    <nav className={styles.navigation}>
      <Item
        identifier='sidebar-landing-pages'
        to={
          location.pathname === LANDINGS.WELCOME
            ? LANDINGS.WELCOME
            : (LANDINGS.LANDINGS as ExpandType)
        }
        text={t('sidebar.landing-pages')}
        icon='icon-web'
        sidebarMini={isSidebarMini}
        expand={{
          id: groupRoute(landingsActiveGroup) || LANDINGS.LANDINGS,
          currentExpand,
          setCurrentExpand
        }}
      >
        <ItemGroup
          identifier='sidebar-group-landing-pages'
          to={
            location.pathname === LANDINGS.WELCOME
              ? LANDINGS.WELCOME
              : LANDINGS.LANDINGS
          }
          text={t('sidebar.landing-pages')}
        />

        {hasAccessToSPASmartSections ? (
          <ItemGroup
            identifier='sidebar-group-smart-sections'
            to={SMART_SECTIONS.SMART_SECTIONS}
            hasAccessToPage={hasAccessToSmartSections}
            text={t('sidebar.smart-sections')}
          />
        ) : (
          <ItemGroup
            identifier='sidebar-group-smart-sections'
            onClick={() =>
              handleOpenTab({
                path: SMART_SECTIONS.SMART_SECTIONS,
                hasAccessToPath: hasAccessToSmartSections
              })
            }
            text={t('sidebar.smart-sections')}
          />
        )}

        {hasAccessToSPALightboxesPage ? (
          <ItemGroup
            identifier='sidebar-group-lightboxes'
            to={LIGHTBOXES.LIGHTBOXES}
            hasAccessToPage={hasAccessToLightBoxes}
            upgradeType={UPGRADE_MODALS.LIGHTBOXES}
            text={t('sidebar.lightboxes')}
          />
        ) : (
          <ItemGroup
            identifier='sidebar-group-lightboxes'
            onClick={() =>
              handleOpenTab({
                path: LIGHTBOXES.LIGHTBOXES,
                hasAccessToPath: hasAccessToLightBoxes
              })
            }
            text={t('sidebar.lightboxes')}
          />
        )}

        {hasAccessToSPAProductsPage && hasAccessToTab(TABS.PRODUCTS) ? (
          <ItemGroup
            identifier='sidebar-group-products'
            to={PRODUCTS.PRODUCTS}
            hasAccessToPage={ECOMMERCE_MODULE_FF}
            upgradeType={UPGRADE_MODALS.ECOMMERCE}
            text={t('sidebar.products')}
          />
        ) : null}

        {!hasAccessToSPAProductsPage && hasAccessToTab(TABS.PRODUCTS) ? (
          <ItemGroup
            identifier='sidebar-group-products'
            onClick={() =>
              handleOpenTab({
                path: `/ecommerce${PRODUCTS.PRODUCTS}`,
                hasAccessToPath: ECOMMERCE_MODULE_FF
              })
            }
            text={t('sidebar.products')}
          />
        ) : null}
      </Item>

      <Item
        identifier='sidebar-popups'
        onClick={() =>
          handleOpenTab({
            path: POPUPS.POPUPS_OLD,
            hasAccessToPath: hasAccesToPopups
          })
        }
        text={t('sidebar.popups')}
        icon='icon-popup'
        sidebarMini={isSidebarMini}
        expand={{
          id: POPUPS.POPUPS as ExpandType,
          currentExpand,
          setCurrentExpand
        }}
      />

      <Spacer space={10} />

      <Item
        identifier='sidebar-conversions'
        to={LEADS.LEADS}
        text={t('sidebar.conversions')}
        icon='icon-users'
        sidebarMini={isSidebarMini}
        expand={{
          id: groupRoute(conversionsActiveGroup) || LEADS.LEADS,
          currentExpand,
          setCurrentExpand
        }}
      >
        {hasAccessToTab(TABS.LEADS) ? (
          <ItemGroup
            identifier='sidebar-group-leads'
            href={`${NEW_LANDINGI_APP_URL}${LEADS.LEADS}`}
            text={t('sidebar.leads')}
            counterValue={leadsCount}
          />
        ) : null}

        {hasAccessToSPAOrdersPage && hasAccessToTab(TABS.ORDERS) ? (
          <ItemGroup
            identifier='sidebar-group-orders'
            to={ORDERS.ORDERS}
            text={t('sidebar.orders')}
          />
        ) : null}

        {!hasAccessToSPAOrdersPage && hasAccessToTab(TABS.ORDERS) ? (
          <ItemGroup
            identifier='sidebar-group-orders'
            href={`${NEW_LANDINGI_APP_URL}/ecommerce${ORDERS.ORDERS}`}
            text={t('sidebar.orders')}
          />
        ) : null}
      </Item>

      <Item
        identifier='sidebar-domains'
        onClick={() => handleOpenTab({ path: DOMAINS.DOMAINS })}
        text={t('sidebar.domains')}
        icon='icon-globe'
        sidebarMini={isSidebarMini}
        expand={{
          id: DOMAINS.DOMAINS as ExpandType,
          currentExpand,
          setCurrentExpand
        }}
      />

      <Item
        identifier='sidebar-fonts'
        onClick={() =>
          handleOpenTab({
            path: `${FONTS.FONTS}/list`,
            hasAccessToPath: hasAccessToFonts
          })
        }
        text={t('sidebar.fonts')}
        icon='icon-font'
        sidebarMini={isSidebarMini}
        expand={{
          id: FONTS.FONTS as ExpandType,
          currentExpand,
          setCurrentExpand
        }}
      />

      {hasAccessToTab(TABS.LIMIT_PACKAGES) ||
      hasAccessToTab(TABS.TEMPLATES) ||
      hasAccessToTab(TABS.PROGRAMMATIC) ? (
        <Spacer space={10} />
      ) : null}

      {hasAccessToTab(TABS.PROGRAMMATIC) ? (
        <Item
          identifier='sidebar-programmatic'
          to={
            hasAccessToProgrammaticPage
              ? (PROGRAMMATIC.PROGRAMMATIC as ExpandType)
              : undefined
          }
          onClick={
            !hasAccessToProgrammaticPage
              ? () =>
                  handleOpenTab({
                    path: PROGRAMMATIC.PROGRAMMATIC,
                    hasAccessToPath: false
                  })
              : undefined
          }
          text={t('sidebar.programmatic')}
          icon='icon-programmatic'
          sidebarMini={isSidebarMini}
          expand={{
            id: PROGRAMMATIC.PROGRAMMATIC as ExpandType,
            currentExpand,
            setCurrentExpand
          }}
        />
      ) : null}

      {hasAccessToTab(TABS.LIMIT_PACKAGES) ? (
        <Item
          identifier='sidebar-limits-packages'
          onClick={() => handleOpenTab({ path: AGENCY.AGENCY_RESOURCE_LIMITS })}
          text={t('sidebar.limits-packages')}
          icon='icon-package'
          sidebarMini={isSidebarMini}
          expand={{
            id: AGENCY.AGENCY_RESOURCE_LIMITS as ExpandType,
            currentExpand,
            setCurrentExpand
          }}
        />
      ) : null}

      {hasAccessToTab(TABS.TEMPLATES) ? (
        <Item
          identifier='sidebar-templates'
          onClick={() =>
            handleOpenTab({ path: `/account${TEMPLATES.TEMPLATES}` })
          }
          text={t('sidebar.templates')}
          icon='icon-templates'
          sidebarMini={isSidebarMini}
          expand={{
            id: TEMPLATES.TEMPLATES as ExpandType,
            currentExpand,
            setCurrentExpand
          }}
        />
      ) : null}

      <Spacer space={10} />

      {hasAccessToTab(TABS.ADD_ONS) ? getAddonsItem() : null}

      {hasAccessToTab(TABS.INTEGRATIONS) ? (
        <Item
          identifier='sidebar-integrations'
          onClick={handleOpenIntegrations}
          text={t('sidebar.integrations')}
          icon='icon-plug'
          sidebarMini={isSidebarMini}
          hasExternalLink
        />
      ) : null}

      {isBiggerMobile && (
        <Fragment>
          <Spacer space={10} />

          <Item
            identifier='topbar-account-label'
            to={MY_ACCOUNT}
            text={userName || t('topbar.account.label')}
            icon='icon-users-circle'
            sidebarMini={isSidebarMini}
            expand={{
              id: MY_ACCOUNT as ExpandType,
              currentExpand,
              setCurrentExpand
            }}
          >
            <Account isBiggerMobile={isBiggerMobile} />
          </Item>
        </Fragment>
      )}
    </nav>
  )
}

SidebarNavigation.displayName = 'SidebarNavigation'
