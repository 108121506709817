import client from '@lib/http/client'

export interface CreateSmartSectionRequest {
  uuid: string
  name: string
  width: number
}

export const createSmartSection = ({
  uuid,
  name,
  width
}: CreateSmartSectionRequest) =>
  client.post(`landing-page/smart-sections/empty`, {
    uuid,
    width,
    name
  })
