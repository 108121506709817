import { AxiosError } from 'axios'
import useSWRImmutable from 'swr/immutable'

interface GetSignInDateRequest {
  action: string
  enabled?: boolean
}

export interface GetSignInDateResponse {
  date: string | null
}

export const useGetSignInDate = ({
  action,
  enabled = true
}: GetSignInDateRequest) => {
  const { data, error, isLoading, isValidating, mutate } = useSWRImmutable<
    GetSignInDateResponse,
    AxiosError
  >(enabled ? `auth/sign-in-date/get-date?action=${action}` : null)

  return {
    data: data ?? { date: null },
    isLoading,
    isValidating,
    error,
    mutate
  }
}
