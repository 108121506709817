import { APP_LANDINGI_URL } from '@config/env'
import { useUserContext } from '@contexts/user'
import { AxiosError } from 'axios'
import useSWRImmutable from 'swr/immutable'

export type ProductUpdatesRequest = {
  page?: number
  limit?: number
  filters?: {
    category?: string
    phrase?: string
  }
}

export type ProductUpdateType = {
  uuid: string
  author: string
  content_pl: {
    title: string
    description: string
    link: string
    img_url: string
    button_label: string
  }
  content_en: {
    title: string
    description: string
    link: string
    img_url: string
    button_label: string
  }
  category: 'feature' | 'improvement' | 'fix' | 'deleted'
  published_at: string
  is_emphasized: boolean
}

export type ProductUpdatesResponse = {
  collection: ProductUpdateType[]
  pagination: {
    counter: {
      current: number
      total: number
    }
  }
}

export const useGetProductUpdates = ({
  page = 1,
  limit = 10,
  filters = {}
}: ProductUpdatesRequest = {}) => {
  const { userID } = useUserContext()

  const searchParams = new URLSearchParams({
    page: String(page),
    limit: String(limit)
  })

  if (filters) {
    Object.entries(filters).forEach(([key, value]) => {
      searchParams.append(`filters[${key}]`, String(value))
    })
  }

  // URL with prefix because the configured Axios Base URL include "/api". "/api" is not present in this case.
  const { data: notificationsData, isLoading: notificationsLoading } =
    useSWRImmutable<ProductUpdatesResponse, AxiosError>(
      `/changelog/notifications?${searchParams.toString()}`
    )

  // URL with prefix because the configured Axios Base URL include "/api". "/api" is not present in this case.
  const { data: lastSeenData, isLoading: lastSeenLoading } = useSWRImmutable(
    `${APP_LANDINGI_URL}/changelog/users/${userID}`
  )

  const {
    data: emphasizedNotificationData,
    isLoading: emphasizedNotificationLoading,
    mutate: emphasizedNotificationMutate
  } = useSWRImmutable('/changelog/last_unseen_emphasized_notification', {
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      if (retryCount >= 3) return
    }
  })

  return {
    notificationsData,
    notificationsLoading,
    lastSeenData,
    lastSeenLoading,
    emphasizedNotificationData,
    emphasizedNotificationLoading,
    emphasizedNotificationMutate
  }
}
